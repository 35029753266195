import React, { useState } from 'react';
import { Card, Modal, Button } from 'antd';

export default function SolveInflation({ footer, home }) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Solving Inflation"
  const type_button = footer ? "link" : home ? "primary" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
        {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}
        align={"center"}
        width={"75%"}
      ><Card >
          <div align="left" style={{ margin: -120, lineHeight: 2 }} >        <br></br> <br></br>
            <h1 style={{ lineHeight: 2 }}>How do we solve Inflation?</h1>
            {/* <p>
              We solve one unique problem, the problem of inflation, and we do it well.</p> */}
            <p>Built-in at the core of WaterWood, are 2 Unique Features that differentiate us from any other passive-income generating coin:</p>
            {/* <br></br>
            <h1>Two Unique Features</h1> */}

            {/* Wich makes WaterWood  compared to any passive income generating Crypto out there: */}

            <br></br>
            <h2>1) A Dual Coin Ecosystem</h2>
            <br></br>
            <p><b>WATER acts as a fixed supply and WOOD as dividend. </b></p>
            <br></br>
            <p>WATER, like Bitcoin, doesn't know inflation.</p><p>
              It's 1,386,000,000 tokens are minted and <b>no more can ever exist.</b></p><p>
              Locking WATER raises it's rarity, and the price of WATER goes up with time.</p>
            <br></br>
            <p>
              WOOD is like Cardano, Polkadot, it is a staking coin, it generates itself as dividends.</p>
            <p>
              But WOOD has mechanisms that reduce it's inflation by <b>61.8%</b>, we will see later why <b>61.8%</b> is important and why <b>WaterWood has revolutionary concepts in it's core.</b></p>
            <br></br>
            <h2>2) WaterWood mathematics</h2>
            <br></br>
            <p><b>WaterWood APY is regulated by both Mathematics and the theory of complex systems</b></p>
            <br></br>
            <p>
              Users <b>Burning their own WOOD</b> is at the core of WOOD wisdom. Our users burn their own WOOD because it's incentivised. </p><p>The more they burn, the more Bonus they get (up to 50x) which makes them earn more WOOD in return. </p><p>The magic resides in the fact that your <b>Burn Bonus</b> is determined against the <b>Global Burn Ratio</b> of the whole community. </p><p>Which makes it impossible for everyone to reach 50x at all time - only a maximum of 2% of the community can reach 50x at any time - (see "Bonus Explained"  in the help section a for more mathematical understanding of the burn Bonus).
              </p><br></br>
             
            <img style={{ width: "100%" }} src="pics/docs/dailymintvsburntWOOD.jpg" alt="Daily minted vs burnt WOOD"></img>
            <br></br> <br></br> 
              
              <p>

                The result is that <b>the market regulates the Burning behaviours</b> of the community.</p><p> And since the Burn Ratio directly impacts your APY, almost everything in the WATERWOOD ecosystem (APY, Burn ratio, WATER/WOOD offer and demand) is regulated by emergent phenomena. </p><p> Ultimately the burning curve follows the law of <b>emergence</b>, driven by the market as a complex systems. Therefore the emerging number of <b>61.8%</b> which some of you have recognized as the <b>Golden Ratio, or fibonacci number</b> is a natural and remarkable consequence of WaterWood design. </p><br></br><p>If you understand the depth of what this means: it means that WaterWood is a natural process, where APYs and Burn Ratios are an expression of nature. </p><p>Any other Cryptocurrency hard-code their variables. This is a unique feature of our system, that is going to create a revolution in the world of Economy. 
                </p><br></br><p>In Layman terms, the <b>Risk and the Return on Investment balance themselves to an Optimal Ratio</b>. It's a natural intelligence! That's the secret of WaterWood, the core hidden gem that makes it a fantastic investment for the long run.</p>
            <br></br>
            {/* <figure> */}
            <img style={{ width: "100%" }} src="pics/docs/mintedWOODvsBurntWOOD.jpg" alt="The Golden Ratio into WaterWood Inflation regulation Mechanism"></img>
            {/* <figcaption>Based on 1 year of data points</figcaption>
            </figure> */}
            <br></br> <br></br> <br></br>
            {/* <img style={{ width: "100%" }} src="pics/docs/dailymintvsburntWOOD.jpg" alt="Daily minted vs burnt WOOD"></img>
            <br></br> <br></br> <br></br> */}
            <p><b>As a bonus, another emergent phenomenon: when you look at the ratio of locked-in WATER vs the total supply, it settles at 71.09% over a whole year of data. Which coincidently or not, is the ratio of WATER vs land surface on planet earth</b></p>
            <br></br> <br></br> <br></br>
            <img style={{ width: "100%" }} src="pics/docs/WaterWoodLockinEquilibrium.jpg" alt="Lockin ratio"></img>
            <br></br> <br></br> <br></br>
            <h1 style={{ lineHeight: 2 }}>Conclusion</h1>
            <p><b>The world is more wholistic than Atomistic, and WaterWood is the first cryptocurrency that is defined, and regulated (APY, Burn ratio, WATER/WOOD offer and demand) by a self-feeding, wholistic, mathematical process driven by complex systems, like the planet itself.</b></p>
            <br></br> <br></br> <br></br>
          </div></Card>
      </Modal>
    </>
  );
};