import { Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

export default function Loader() {
  return (
    <Card style={{marginTop:0,marginBottom:27}}>
      <div style={{ padding: 16, margin: "auto", margin: 0 }}>
        <div style={{fontSize:"102px"}} className="icons-list">
          <LoadingOutlined/>
        </div>
      </div>
    </Card>
  );
}
