import { Input, Switch, Card, Slider, Row, Col } from "antd";
import React, { useState } from "react";
import { Loader } from "../components";

export default function Simulate({ _ }) {
  const [disabled, setDisabled] = useState(true);
  const [burnBonus, setburnBonus] = useState(_.burnMultiplier);
  const [timeBonus, settimeBonus] = useState(_.timeMultiplier);
  const [yourMultiplier, setYourMultiplier] = useState(timeBonus * burnBonus);

  const [unMintedDays, setUnmintedDays] = useState((_.lastBlockNumber - _.lastMintBlockNumber) / 28800);
  const [WATERamount, setWATERamount] = useState(_.lockedInWATER? _.lockedInWATER: 0);
  const initialDuration = 365;
  const [predictedWOOD, setPredictedWOOD] = useState(WATERamount * initialDuration * yourMultiplier * 28800 * 0.00000001);

  const onChangeTime = value => {
    settimeBonus(value);
    setYourMultiplier(value * burnBonus);
    setPredictedWOOD(WATERamount * unMintedDays * value * burnBonus * 28800 * 0.00000001);
  };

  const onChangeBurn = value => {
    setburnBonus(value);
    setYourMultiplier(timeBonus * value);
    setPredictedWOOD(WATERamount * unMintedDays * timeBonus * value * 28800 * 0.00000001);
  };

  const handleDisabledChange = disabled => {
    setDisabled(!disabled);
    if (isNaN(predictedWOOD)) {
      const tB = _.timeMultiplier > 1 ? _.timeMultiplier : 10;
      const bB = _.burnMultiplier > 1 ? _.burnMultiplier : 50;
      const Wa = _.lockedInWATER !== 0 ? _.lockedInWATER : 1000000;
      const uD = _.lastMintBlockNumber !== 0 && _.lockedInWATER!==0? (_.lastBlockNumber - _.lastMintBlockNumber) / 28800 : 30;
      const yM = tB * bB;
      settimeBonus(tB);
      setburnBonus(bB);
      setWATERamount(Wa);
      setUnmintedDays(uD);
      setYourMultiplier(yM);
      setPredictedWOOD(Wa * uD * yM * 28800 * 0.00000001);
    }
  };

  const totalEarned = _.priceWOOD * predictedWOOD;
  const investedAmount = WATERamount*_.priceWATER;

  return (
    <div>
      {!isNaN(_.lastMintBlockNumber) &&
      !isNaN(_.lastBlockNumber) &&
      !isNaN(_.lockedInWATER) &&
      !isNaN(_.burnMultiplier) &&
      !isNaN(_.timeMultiplier) ? (
        <Card style={{ marginBottom: 27 }}>
          <div align="center">
            <div style={{ maxWidth: 650 }}>
              <div style={{ margin: 45 }}>
                <b style={{ fontSize: 40 }}>Simulator</b>
                <Switch
                  size="large"
                  checked={!disabled}
                  onChange={handleDisabledChange}
                  style={{ marginLeft: 15, marginBottom: 15, verticalAlign: "bottom" }}
                />
              </div>

              {!disabled ? (
                <div>
                  <div style={{ margin: 15 }}>
                    <p>
                      Burn Bonus:{" "}
                      {burnBonus
                        ? burnBonus.toLocaleString(3, {
  
                          maximumFractionDigits: 2
                        })
                        : _.lockedInWATER && _.lockedInWATER !== 0
                        ? _.burnMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
})
                        : 50} ({(_.globalRatio*WATERamount*(burnBonus-1)).toLocaleString(3, {
                      
                          maximumFractionDigits: 0
                        })} WOOD to burn)
                    </p>

                    <Slider
                      min={1}
                      max={50}
                      onChange={onChangeBurn}
                      value={
                        burnBonus
                          ? burnBonus
                          : _.lockedInWATER && _.lockedInWATER !== 0
                          ? _.burnMultiplier
                          : 50
                      }
                      step={0.01}
                      disabled={disabled}
                    />
                  </div>
                  <div style={{ margin: 15 }}>
                    <p>
                      Time Bonus:{" "}
                      {timeBonus
                        ? timeBonus.toLocaleString(3, {
  
                          maximumFractionDigits: 2
                        })
                        : _.lockedInWATER && _.lockedInWATER !== 0
                        ? _.timeMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
})
                        : 10}
                    </p>

                    <Slider
                      min={1}
                      max={10}
                      onChange={onChangeTime}
                      value={
                        timeBonus
                          ? timeBonus
                          : _.lockedInWATER && _.lockedInWATER !== 0
                          ? _.timeMultiplier
                          : 10
                      }
                      step={0.01}
                      disabled={disabled}
                    />
                  </div>

                  <div>
                    <div style={{ margin: 15, maxWidth: 350 }}>
                      <p>Lock-in amount: </p>
                      <Input
                        disabled={disabled}
                        size={"large"}
                        defaultValue={WATERamount}
                        placeholder={"Input WATER amount"}
                        suffix={"WATER ( = $"+ investedAmount.toLocaleString(3, {
                          maximumFractionDigits: 2,
                        }) +" )"}
                        onChange={e => {
                          if (e.target.value !== "") {
                            setWATERamount(e.target.value);
                            setPredictedWOOD(e.target.value * unMintedDays * yourMultiplier * 28800 * 0.00000001);
                          }
                        }}
                      />
                    </div>

                    <div style={{ margin: 15, maxWidth: 350 }}>
                      <p>Lock-in duration: </p>
                      <Input
                        disabled={disabled}
                        size={"large"}
                        defaultValue={initialDuration}
                        placeholder={"Days locked-in"}
                        suffix={"days"}
                        onChange={e => {
                          if (e.target.value !== "") {
                            setUnmintedDays(e.target.value);
                            setPredictedWOOD(WATERamount * e.target.value * yourMultiplier * 28800 * 0.00000001);
                          }
                        }}
                      />
                    </div>

                    <div style={{ margin: 45 }}>
                      <p>Cash-In:</p>
                      <b>
                        <p style={{ fontSize: 30 }}>{predictedWOOD.toLocaleString(3, {
  
  maximumFractionDigits: 2
})} WOOD</p>
                      </b>

                      <b class="boxed" style={{fontSize: 40 }}>
                      {/* =<br></br> */}
                      Earn $
                      {totalEarned.toLocaleString(3, {
                        maximumFractionDigits: 2,
                      })}
                 
                  </b>

                    </div>
                    <p style={{ fontSize: 15 }}>
     Based on current prices of:
     <br></br>
                    <b>WATER: $
                    {_.priceWATER.toLocaleString(3, {
                      maximumFractionDigits: 5,
                    })}</b>
<br></br><b>WOOD: $
                    {_.priceWOOD.toLocaleString(3, {
                      maximumFractionDigits: 5,
                    })}</b>                 
                     </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Loader />
      )}
    </div>
  );
}
