import React from 'react';
import ReactDOM from 'react-dom';
import { SocialIcon } from 'react-social-icons';
import {Card} from "antd";


export default function SocialBar2() {
  const s = 180;
  const m = s/5;
  return (
    <Card style={{marginTop:0,marginBottom:27}}>
   
              <b>
                <b>
                  <div>
                    <font size="164">Need help ?<br></br></font>
                    <font size="5">Join our group chat</font>
                  </div>
                </b>
              </b>
      <div style={{ padding: 16, margin: "auto", margin: 0 }}>
        <div style={{margin:45}}>
        {/* <SocialIcon network="telegram" target="_blank" url="https://t.me/waterwoodfinance" style={{ margin:m,height: s, width: s }} /> */}
        <SocialIcon target="_blank" url="https://discord.com/invite/Z2KESHVrt3" style={{ margin:m,height: s, width: s }} />
        {/* <SocialIcon target="_blank" url="https://twitter.com/waterwoodmoney" style={{ margin:m,height: s, width: s }} />
        <SocialIcon target="_blank" url="https://medium.com/@waterwood.finance" style={{ margin:m,height: s, width: s }} />
        <SocialIcon target="_blank" url="https://www.reddit.com/r/Waterwood/" style={{ margin:m,height: s, width: s }} />

        <SocialIcon target="_blank" url="https://www.linkedin.com/company/waterwood-finance" style={{ margin:m,height: s, width: s }} />
        <SocialIcon target="_blank" url="https://www.facebook.com/WaterWood-Finance-100572899098211" style={{ margin:m,height: s, width: s }} />
        <SocialIcon target="_blank" url="https://github.com/Waterwood-network" style={{ margin:m,height: s, width: s }} /> */}
        </div>
      </div>
    </Card>
  );
}
