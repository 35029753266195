import { Input, Card, Slider } from "antd";
import React, { useState } from "react";
import { NETWORKS } from "../constants";
import { receiveBalance } from "../helpers";
import {useContractReader,useExternalContractLoader} from "../hooks";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
// import { Loader } from "../components";


const targetNetwork = NETWORKS.bsc; 
const localProvider = new StaticJsonRpcProvider(targetNetwork.rpcUrl);
const addressWOODContract = targetNetwork.wood_address;

  

export default function Simulation({ priceBNB, priceWATER, priceWOOD }) {

  const customContractWOOD = useExternalContractLoader(localProvider, addressWOODContract, targetNetwork.wood_abi);

  const globalRatio = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "getGlobalRatio"))*10**8;
  const [burnBonus, setburnBonus] = useState(25);
  const [timeBonus, settimeBonus] = useState(10);
  const [yourMultiplier, setYourMultiplier] = useState(timeBonus * burnBonus);

  const [unMintedDays, setUnmintedDays] = useState(365);
  const [WATERamount, setWATERamount] = useState(1000000);
  const [predictedWOOD, setPredictedWOOD] = useState(WATERamount * unMintedDays * yourMultiplier * 28800 * 0.00000001);

  const onChangeTime = value => {
    settimeBonus(value);
    setYourMultiplier(value * burnBonus);
    setPredictedWOOD(WATERamount * unMintedDays * value * burnBonus * 28800 * 0.00000001);
  };

  const onChangeBurn = value => {
    setburnBonus(value);
    setYourMultiplier(timeBonus * value);
    setPredictedWOOD(WATERamount * unMintedDays * timeBonus * value * 28800 * 0.00000001);
  };

  const totalEarned = priceWOOD * predictedWOOD;
  // const ROI = (totalEarned / (priceWATER * WATERamount)) * 100;
  const investedAmount = WATERamount*priceWATER;

  
  
  return (
    <div>
      <Card style={{ marginBottom: 27 }}>
        <div align="center">
          <div style={{ maxWidth: 650 }}>
            <div >
              <b style={{ fontSize: 40 }}>Simulate Profits</b>
            </div>

            <div>
              <div style={{ margin: 15 }}>
                <p>Burn Bonus: {burnBonus}{" "}
                ({(globalRatio*WATERamount*(burnBonus-1)).toLocaleString(3, {
                      
                      maximumFractionDigits: 0
                    })} WOOD to burn)
                </p>

                <Slider min={1} max={50} onChange={onChangeBurn} value={burnBonus} step={0.1} />
              </div>
              <div style={{ margin: 15 }}>
                <p>Time Bonus: {timeBonus}</p>

                <Slider min={1} max={10} onChange={onChangeTime} value={timeBonus} step={0.1} />
              </div>

              <div>
                <div style={{ margin: 15, maxWidth: 400 }}>
                  <p>Lock-in amount: </p>
                  <Input
                    size={"large"}
                    defaultValue={WATERamount}
                    placeholder={"Input WATER amount"}
                    suffix={"WATER ( = $"+ investedAmount.toLocaleString(3, {
                      maximumFractionDigits: 2,
                    }) +" )"}
                    onChange={e => {
                      if (e.target.value !== "") {
                        setWATERamount(e.target.value);
                        setPredictedWOOD(e.target.value * unMintedDays * yourMultiplier * 28800 * 0.00000001);
                      }
                    }}
                  />
                  
                </div>

                <div style={{ margin: 15, maxWidth: 400 }}>
                  <p>Lock-in duration: </p>
                  <Input
                    size={"large"}
                    defaultValue={unMintedDays}
                    placeholder={"Days locked-in"}
                    suffix={"days"}
                    onChange={e => {
                      if (e.target.value !== "") {
                        setUnmintedDays(e.target.value);
                        setPredictedWOOD(WATERamount * e.target.value * yourMultiplier * 28800 * 0.00000001);
                      }
                    }}
                  />
                </div>

                <div  style={{ margin: 5 }}>
                  <p>Cash-In:</p>
                  <b>
                    <p style={{ fontSize: 25 }}>
                      {predictedWOOD.toLocaleString(3, {
                        maximumFractionDigits: 2,
                      })}{" "}
                      WOOD
                    </p>{" "}
                  </b>
               {/* <br></br> */}
 <b class="boxed" style={{fontSize: 40 }}>
                      {/* =<br></br> */}
                      Earn $
                      {totalEarned.toLocaleString(3, {
                        maximumFractionDigits: 2,
                      })}
                 
                  </b>
                  
                </div>
                  <p style={{ fontSize: 15 }}>
     Based on current prices of:
     <br></br>
                    <b>WATER: $
                    {priceWATER.toLocaleString(3, {
                      maximumFractionDigits: 5,
                    })}</b>
<br></br><b>WOOD: $
                    {priceWOOD.toLocaleString(3, {
                      maximumFractionDigits: 5,
                    })}</b>                 
                     </p>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
