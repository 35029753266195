import { StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Alert } from "antd";
import { useUserAddress } from "eth-hooks";
import React, { useCallback, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import {
  Simulate,
  Account,
  APYInfo,
  DownloadMetamask,
  EcosystemInfo,
  ENABLE,
  GrowWOOD,
  BuyWATER,
  Loader,
  Multipliers,
  TokenInfo,
} from "../components";
import { STYLE, INFURA_ID, NETWORK, NETWORKS } from "../constants";
import { receiveArray, receiveArrayNumberElement, receiveBalance, Transactor } from "../helpers";
import { useBalance, useContractReader, useExternalContractLoader, useGasPrice, useUserProvider } from "../hooks";

const DEBUG = 0;

const targetNetwork = NETWORKS.bsc; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

const ethereumProvider = new StaticJsonRpcProvider(NETWORKS.mainnet.rpcUrl);
const bscProvider = new StaticJsonRpcProvider(NETWORKS.bsc.rpcUrl);
const localProvider = new StaticJsonRpcProvider(targetNetwork.rpcUrl);
const mainnetProvider = bscProvider; // && localProvider._network ? localProvider : bscProvider;

const addressWOODContract = targetNetwork.wood_address;
const addressWATERContract = targetNetwork.water_address;

// 🔭 block explorer URL
const blockExplorer = targetNetwork.blockExplorer;

/*
  Web3 modal helps us "connect" external wallets:
*/
const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: INFURA_ID,
      },
    },
  },
});

const logoutOfWeb3Modal = async () => {
  await web3Modal.clearCachedProvider();
  setTimeout(() => {
    window.location.reload();
  }, 1);
};

export default function Dashboard({ priceBNB, priceWATER, priceWOOD }) {
  const [injectedProvider, setInjectedProvider] = useState();

  /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  const gasPrice = useGasPrice(targetNetwork, "fast");
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProvider = useUserProvider(injectedProvider, localProvider);
  const address = useUserAddress(userProvider);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const userChainId = userProvider && userProvider._network && userProvider._network.chainId;

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userProvider, gasPrice);
  const tx_without_notifications = Transactor(userProvider, gasPrice, false, false);

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address);

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address);

  const customContractWOOD = useExternalContractLoader(localProvider, addressWOODContract, targetNetwork.wood_abi);

  const customContractWATER = useExternalContractLoader(localProvider, addressWATERContract, targetNetwork.water_abi);

  const totalSupplyWATER = receiveBalance(useContractReader({ 1: customContractWATER }, 1, "totalSupply"));

  const totalSupplyWOOD = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "totalSupply"));

  const lockedAddressInfo =
    receiveArray(useContractReader({ 1: customContractWOOD }, 1, "addressLocks", [address])) || "    ";

  const lockedInWATER = receiveArrayNumberElement(0, lockedAddressInfo);
  const burnedWOOD = receiveArrayNumberElement(1, lockedAddressInfo);
  const firstLockInBlock = parseInt(lockedAddressInfo[2].hex);
  const lastMintBlockNumber = parseInt(lockedAddressInfo[3].hex);

  const addressInfo =
    receiveArray(useContractReader({ 1: customContractWOOD }, 1, "getAddressDetails", [address])) || "       ";

  const lastBlockNumber = parseInt(addressInfo[0].hex);
  const WOODBalance = receiveArrayNumberElement(1, addressInfo);
  const WOODmintAmount = receiveArrayNumberElement(2, addressInfo);
  const timeMultiplier = parseInt(addressInfo[3].hex) / 10 ** 4;
  const burnMultiplier = parseInt(addressInfo[4].hex) / 10 ** 4;
  const globalLockedamount = receiveArrayNumberElement(5, addressInfo);
  const globalBurnedamount = receiveArrayNumberElement(6, addressInfo);

  const addressTokenInfo = receiveArray(
    useContractReader({ 1: customContractWOOD }, 1, "getAddressTokenDetails", [address]) || "     ",
  );

  const isWOODOperator = addressTokenInfo[1];
  const WATERBalance = receiveArrayNumberElement(2, addressTokenInfo);
  const myRatio = parseInt(addressTokenInfo[3].hex) / 10 ** 10;
  const globalRatio = parseInt(addressTokenInfo[4].hex) / 10 ** 10;

  const signer = userProvider.getSigner();

  const _ = {
    address: address,
    addressWATERContract: addressWATERContract,
    addressWOODContract: addressWOODContract,
    blockExplorer: blockExplorer,
    burnMultiplier: burnMultiplier,
    burnedWOOD: burnedWOOD,
    customContractWATER: customContractWATER,
    customContractWOOD: customContractWOOD,
    ethereumProvider: ethereumProvider,
    firstLockInBlock: firstLockInBlock,
    gasPrice: gasPrice,
    globalBurnedamount: globalBurnedamount,
    globalLockedamount: globalLockedamount,
    globalRatio: globalRatio,
    injectedProvider: injectedProvider,
    isWOODOperator: isWOODOperator,
    lastBlockNumber: lastBlockNumber,
    lastMintBlockNumber: lastMintBlockNumber,
    localChainId: localChainId,
    localProvider: localProvider,
    lockedInWATER: lockedInWATER,
    mainnetProvider: mainnetProvider,
    myRatio: myRatio,
    priceBNB: priceBNB,
    priceWATER: priceWATER,
    priceWOOD: priceWOOD,
    signer: signer,
    STYLE: STYLE,
    timeMultiplier: timeMultiplier,
    totalSupplyWATER: totalSupplyWATER,
    totalSupplyWOOD: totalSupplyWOOD,
    tx: tx,
    tx_without_notifications: tx_without_notifications,
    userChainId: userChainId,
    userProvider: userProvider,
    WATERBalance: WATERBalance,
    WOODBalance: WOODBalance,
    WOODmintAmount: WOODmintAmount,
    yourLocalBalance: yourLocalBalance,
    yourMainnetBalance: yourMainnetBalance,
  };

  let networkDisplay = "";
  if (localChainId && userChainId && localChainId !== userChainId) {
    const networkLocal = NETWORK(localChainId);
    if (userChainId === 1337 && localChainId === 31337) {
      networkDisplay = (
        <div style={{ zIndex: 2, position: "fixed", right: 0, top: 45, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network ID"
            description={
              <div>
                You have <b>chain id 1337</b> for localhost and you need to change it to <b>31337</b> to work with
                HardHat.
                <div>(MetaMask -&gt; Settings -&gt; Networks -&gt; Chain ID -&gt; 31337)</div>
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    } else {
      networkDisplay = (
        <div style={{ zIndex: 22, position: "fixed", right: 0, top: 45, padding: 16 }}>
          <Alert
            message="⚠️ Wrong Network"
            description={
              <div>
                {/* You have <b>{networkSelected && networkSelected.name}</b> selected and you need to be on{" "} */}
                You need to be on <b>{networkLocal && networkLocal.name}</b>.
              </div>
            }
            type="error"
            closable={false}
          />
        </div>
      );
    }
  } else {
    networkDisplay = (
      <div style={{ zIndex: -1, position: "absolute", right: 154, top: 28, padding: 16, color: targetNetwork.color }}>
        {/* {targetNetwork.name} */}
      </div>
    );
  }

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3Provider(provider));
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [isChecked, setisChecked] = useState(false);
  const [isEnabled, setisEnabled] = useState(false);
  if (customContractWATER && !isEnabled)
    tx_without_notifications(customContractWATER.connect(signer)["isOperatorFor"](addressWOODContract, address)).then(
      res => {
        setisChecked(true);
        setisEnabled(res);
      },
    );

  if (DEBUG) {
    console.log(_);
  }

  return (
    <div>
      {networkDisplay}

      {!(localChainId && userChainId) || localChainId !== userChainId ? (
        <Loader />
      ) : !injectedProvider ? (
        //   <Card><div>
        //   <div style={{  margin: 24 }}>
        //   <Account
        //     // typeB={"link"}
        //     address={address}
        //     ensProvider={ethereumProvider}
        //     web3Modal={web3Modal}
        //     loadWeb3Modal={loadWeb3Modal}
        //     logoutOfWeb3Modal={logoutOfWeb3Modal}
        //   /> <div style={{align:"center"}}>{" "}or{" "}</div>
        //     {
        //       <Button rel="noopener noreferrer" target="_blank" href="https://metamask.io/download">
        //         download Metamask
        //       </Button>
        //     }
        //   </div>
        // </div></Card>
        <DownloadMetamask _={_} />
      ) : (
        <div style={{}}>
          {!isChecked && !isEnabled ? (
            <Loader />
          ) : !isEnabled && isChecked ? (
            <ENABLE _={_} />
          ) : !isNaN(_.WATERBalance) &&
            _.WATERBalance !== "NaN" &&
            _.WATERBalance !== undefined &&
            _.WATERBalance === 0  && !isNaN(_.lockedInWATER) && _.lockedInWATER !== undefined &&
              _.lockedInWATER === 0 ?(
            <BuyWATER _={_} />
          ) : (
            <GrowWOOD _={_} />
          )}
        </div>
      )}

     
  

      <div style={{ zIndex: 1, position: "fixed", textAlign: "right", right: 0, top: 0, padding: 10 }}>
        <Account
          // type={"primary"}
          address={address}
          ensProvider={ethereumProvider}
          web3Modal={web3Modal}
          loadWeb3Modal={loadWeb3Modal}
          logoutOfWeb3Modal={logoutOfWeb3Modal}
        />
      </div>
    </div>
  );
}

/* eslint-disable */
window.ethereum &&
  window.ethereum.on("chainChanged", _chainId => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });

window.ethereum &&
  window.ethereum.on("accountsChanged", _accounts => {
    web3Modal.cachedProvider &&
      setTimeout(() => {
        window.location.reload();
      }, 1);
  });
/* eslint-enable */
