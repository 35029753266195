// import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";

// const themes = {
//   dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
//   light: `${process.env.PUBLIC_URL}/light-theme.css`,
 
// };

// const prevTheme = window.localStorage.getItem("theme");

// const subgraphUri = "http://localhost:8000/subgraphs/name/scaffold-eth/your-contract";

// const client = new ApolloClient({
//   // uri: subgraphUri,
//   cache: new InMemoryCache(),
// });

ReactDOM.render(<div>
  {/* // <ApolloProvider client={client}> */}
    {/* <ThemeSwitcherProvider themeMap={themes} defaultTheme={ "light"}> */}
    {/* <ThemeSwitcherProvider themeMap={themes} defaultTheme={"dark"}> */}
      <App />
    {/* </ThemeSwitcherProvider> */}
    
    </div>,
  // </ApolloProvider>,
  document.getElementById("root"),
);