import React, { useState } from 'react';
import { Modal, Button, Card } from 'antd';
import { FallOutlined, RiseOutlined } from "@ant-design/icons";

export default function WhyWaterwood({footer}) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Why WaterWood"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
       {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}
        align={"center"}
        width={"75%"}
      ><Card >
          <div align="left" style={{ margin: -120, lineHeight: 2 }} >
            {/* <h1 style={{ fontSize: 40 }}></h1> */}
            {/* <h3><b>Waterwood Network</b> is a gateway into next-gen Deflationary Monetary Systems that is OWNERLESS, IMMUTABLE, AUDITED and ran completely by the community. Our revolutionary Binance smart contracts form Base Currencies of DeFi.</h3> */}
            <br></br>   <br></br>

            <h1 style={{ lineHeight: 1.5 }}>
              SOLVING INFLATION, CONTROLLED BY THE PEOPLE
            </h1>
            <p>

              WATER and WOOD aren't subjected to inflation and are controlled by the people.
            </p>

            {/* <br></br> */}
            <p>
              WOOD's inflation is determined by offer and demand. Regulation by the market offers invaluable,{" "}
              <b>never seen before advantages</b>
              {/* putting the control into people's hands.  */}
              {/* in any money
                systems, including in Defi */}
              :
            </p>
            <br></br>  <br></br>

            <h1 style={{ lineHeight: 1.5 }}>SAFEST APY, CONTROLLED BY MARKET FORCES</h1>


            <p>
              APY is Automatically Optimized for Risk vs Reward. The Market takes care of everything.


              WaterWood, is first-in-kind to experiment with a dual coin that combines market wisdom, and swift
              mathematical formulas, only made possible by smart contracts.
              <br />
              {/* WATER is locked-in, it becomes rarer in the market, increasing it's value.</p> */}
              As price and burn ratio are driven by offer and demand, WOOD APY systematically stabilizes at an optimal
              risk/reward ratio. <b>This is a unique innovation</b>, made only possible through decentralized blockchain
              technology.
              {/* Making it more valuable than any other staking, or dividend based DeFi coins
                in the market. A global optima is reached along with liquidity.  */}
            </p>
            {/* <p>
                3. As people realize the unbelievable value of a dual coin system, price of both coins grow
                without being restrained by WOOD generation
              </p>
              <br></br>
              <p>
                View it as WATER is finite on earth, always has the same quantity, is essential to life, and it's value
                increases where it's rare (aka. locking-in WATER)
              </p> */}
            {/* <p>
                WOOD needs WATER to grow, it can grow infinitely, but its quantity is still finite, because it's
                subjected to it's harsh environment (the law of the market) where it gets burned.{" "}
              </p> */}

            <br></br> <br></br>
            <h1 style={{ lineHeight: 1.5 }}>
              SECURE BY DESIGN & PROFESSIONALLY AUDITED</h1>


            <p>
              All business logic is executed via Audited Smart Contracts so your funds are safe and secure. No 3rd parties
              are involved in fund movement and transactions are performed on-chain.
            </p>

            <br></br><br></br>
            <h1 style={{ lineHeight: 1.5 }}>SELF-PROGRAMMED FOR GROWTH</h1>


            <p>
              A Financial Mechanism that perpetuates stability and growth over time
            </p>
            <br></br>
            <h2>

              <b> Upward cycle </b>
              <Button type="link" icon={<RiseOutlined />}></Button>

            </h2>
            {/* <p>
                As people buy WATER. Price goes up<Button type="link" icon={<RiseOutlined />}></Button> People lock-in WATER to generate WOOD dividends. Increasing WATER rarity. Price rises further{" "}
                <Button type="link" icon={<RiseOutlined />}></Button>
              </p>
              <p>
                As people buy WOOD. Price goes up<Button type="link" icon={<RiseOutlined />}></Button> People burn WOOD to increase their Multiplier. Making WOOD rare. Price rises further
                <Button type="link" icon={<RiseOutlined />}></Button>
              </p> */}
            <p >
              As people lock-in WATER. WATER increases in rarity. Price goes up
              {/* <Button type="link" icon={<RiseOutlined />}></Button> */}
            </p>
            <p >
              As people burn WOOD. WOOD increases in rarity. Price goes up
              {/* <Button type="link" icon={<RiseOutlined />}></Button> */}
            </p>
            <br></br>
            <h2><b> Downward cycle</b> <Button type="link" icon={<FallOutlined />}></Button></h2>

            <p >
              As WATER rises in price, people unlock WATER and sell
              {/* <Button type="link" icon={<FallOutlined />}></Button> */}
            </p>
            <p >
              As WOOD rises in price, people cash-in WOOD in the market
              {/* <Button type="link" icon={<FallOutlined />}></Button> */}
            </p> <br></br>
            <h2>
              <b>Steady long-term growth</b>
              <Button type="link" icon={<RiseOutlined />}></Button>
            </h2>
            {/* <p>
                Demand for dividend doesn't let prices fall too much. As WATER or WOOD fall in price, people seize the opportunity to buy more, burn and lock-in again.
                <Button type="link" icon={<RiseOutlined />}></Button>
              </p>
              <p>
                Since more and more people involved in the ecosystem, prices steadily rise in the long-term as more and more people generate dividends
                <Button type="link" icon={<RiseOutlined />}></Button>
              </p>
              <p>The value of both coins raise steadily while still offering dividends every 3 seconds</p> */}
            <p >
              As liquidity comes in, short-term ups and downs self regulate WaterWood APY that reaches a competitive rate
              among all yields in the market, keeping the price of WATER and WOOD increasing as more people join in. In
              the long term, this cycle is healthy, like the ecological cycle of WATER and WOOD in nature.
              {/* <Button type="link" icon={<RiseOutlined />}></Button> */}
            </p>
            <br></br> <br></br>
          </div>
        </Card>
      </Modal>
    </>
  );
};