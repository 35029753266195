import React, { useState } from 'react';
import { Modal, Button, Card } from 'antd';

export default function CoreValues({footer}) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Core Values"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
       {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}
        align={"center"}
        width={"75%"}
      ><Card >
          <div align="left" style={{margin:-120, lineHeight:2}} >
            {/* <h1 style={{ fontSize: 40 }}></h1> */}
            {/* <h3><b>Waterwood Network</b> is a gateway into next-gen Deflationary Monetary Systems that is OWNERLESS, IMMUTABLE, AUDITED and ran completely by the community. Our revolutionary Binance smart contracts form Base Currencies of DeFi.</h3> */}
            <br></br>   <br></br>
                <h1 style={{lineHeight: 1.5}}>Rarity at the Core</h1>
              <p>

                Waterwood (WATER) tokens have fixed supply. Over 70% of lifetime WATER supply is locked-up in the WOOD smart contract. Constant demand to lock-up more WATER drives rarity.

                WOOD tokens have infinite supply and deflation is automatically adjusted by "controlled burn". Over 60% of all WOOD tokens have been destroyed from circulation.
              </p>
              <br></br><br></br>
                <h1 style={{lineHeight: 1.5}}>Linear Deflation</h1>
              <p>
                WOOD can only be generated by locking-in WATER tokens. WOOD is generated in a linear & predictable manner every 3 seconds. WOOD growth scales linearly based on the amount of WOOD destroyed from circulation.

                PancakeSwap acts as M0 money pools in our DeFi ecosystem. WOOD is constantly "re-cycled" via Burning & WOOD Collecting cycles. With no WATER inflation and controlled WOOD deflation our currencies offer high rarity and growth potential.
              </p>
              {/* <h2>Secure By Design & Professionally Audited</h2><p>All business logic is executed via Audited Smart Contracts so your funds are safe and secure. No 3rd parties are involved in fund movement and transactions are performed on-chain.</p> */}
              <br></br><br></br>
                <h1 style={{lineHeight: 1.5}}>Built For The Community</h1><p>Utilizing the latest serverless, web3 and mobile technologies our Smart Contracts feel like the apps you use and love. Seamlessly switch your experience from desktop to mobile on the same secure and easy-to-use dashboard.</p>
              <br></br>   <br></br>
          </div></Card>
      </Modal>
    </>
  );
};