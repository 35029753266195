import { sendBalance } from "../helpers";
import React, { useState } from "react";
import { Card, Button, Input } from "antd";
import { Loader } from "../components";
import { CaretRightFilled, LockFilled } from "@ant-design/icons";
import { BUYWATERPS } from "../constants";

export default function LockWATER({ _ }) {
  const [newInputValue, setInputValue] = useState(_.WATERBalance);

  return (
    <div>
      <Card
        style={{
          "background-color": _.STYLE.COLORCARD[0],
          marginTop: _.STYLE.MARGINCARD.top,
          marginBottom: _.STYLE.MARGINCARD.bottom,
        }}
      >
        {!isNaN(_.WATERBalance) && _.WATERBalance !== "NaN" && _.WATERBalance !== undefined ? (
          _.WATERBalance === 0 ? (
            <div style={{ margin: 8 }}>
              <div style={{ fontSize: 40 }} plain>
                <div style={{ display: "inline" }}>
                  <b>Buy WATER</b>{" "}
                  <img
                    width="64"
                    heigth="64"
                    style={{ "vertical-align": "top" }}
                    src="/pics/logo_coins_woodwater_large_square_108_reduced.png"
                  ></img>
                </div>
              </div>
              <h2>You don't seem to have WATER. In order to use our service, it's necessary to own WATER </h2>
              <Button
                class="wateh"
                id="buyingWATERDashboard"
                rel="noopener noreferrer"
                target={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                    ? ""
                    : "_blank"
                }
                href={BUYWATERPS}
                style={{ marginTop: 20 }}
                icon={<CaretRightFilled />}
                type="primary"
                size="large"
              >
                Buy WATER{" "}
              </Button>
            </div>
          ) : (
            <div style={{ margin: 8 }}>
              <div style={{ fontSize: 40 }} plain>
                <div style={{ display: "inline" }}>
                  <b>Start by Locking-in WATER</b>{" "}
                  <img
                    width="64"
                    heigth="64"
                    style={{ "vertical-align": "top" }}
                    src="/pics/logo_coins_woodwater_large_square_108_reduced.png"
                  ></img>
                </div>
              </div>
              <h4>
                My WATER Balance:{" "}
                {_.WATERBalance.toLocaleString(3, {
                  maximumFractionDigits: 2,
                }) || 0}
              </h4>
              <div style={{ transform: "translate(50%, 0%)", width: "50%" }}>
                <Input
                  size={"large"}
                  defaultValue={_.WATERBalance}
                  placeholder={_.WATERBalance === 0 ? "WATER balance is 0, click Buy WATER below" : _.WATERBalance}
                  suffix={"WATER"}
                  onChange={e => {
                    if (e.target.value !== "") {
                      setInputValue(e.target.value);
                    } else {
                    }
                  }}
                />

                <Button
                  class="wateh"
                  id="locking"
                  icon={<LockFilled />}
                  size="large"
                  type="primary"
                  style={{ marginTop: 8 }}
                  onClick={async () => {
                    const result = _.tx_without_notifications(
                      _.customContractWOOD
                        .connect(_.signer)
                        ["lock"](
                          _.address,
                          sendBalance(
                            !isNaN(newInputValue) && newInputValue !== "NaN" && newInputValue !== undefined
                              ? newInputValue.toString().includes(".")
                                ? (Math.floor(newInputValue * 10 ** 6) / 10 ** 6).toString()
                                : newInputValue.toString()
                              : _.WATERBalance.toString().includes(".")
                              ? (Math.floor(_.WATERBalance * 10 ** 6) / 10 ** 6).toString()
                              : _.WATERBalance.toString(),
                          ),
                        ),
                      update => {
                        console.log("📡 Transaction Update:", update);
                        if (update && (update.status === "confirmed" || update.status === 1)) {
                          console.log(" 🍾 Transaction " + update.hash + " finished!");
                          console.log(
                            " ⛽️ " +
                              update.gasUsed +
                              "/" +
                              (update.gasLimit || update.gas) +
                              " @ " +
                              parseFloat(update.gasPrice) / 1000000000 +
                              " gwei",
                          );
                        }
                      },
                    );
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                  }}
                >
                  Lock-In WATER
                </Button>
              </div>

              <Button
                class="wateh"
                id="buyingWATERInsideDashboard"
                rel="noopener noreferrer"
                target={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                    ? ""
                    : "_blank"
                }
                href={BUYWATERPS}
                style={{ marginTop: 26 }}
                icon={<CaretRightFilled />}
                type="link"
              >
                Buy WATER{" "}
              </Button>

              {/* <Progress
          strokeColor={{
            from: "#108ee9",
            to: "#87d068",
          }}
          percent={(_.timeMultiplier.toLocaleString() / 10) * 100}
          status="active"
          showInfo={false}
        />
        <div>{(_.timeMultiplier.toLocaleString() || 0) + " / 10"} </div> */}
            </div>
          )
        ) : (
          <Loader />
        )}
      </Card>
    </div>
  );
}
