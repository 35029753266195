import { CalculatorFilled,GoldFilled, ControlFilled, HomeFilled, InfoCircleFilled, MenuOutlined } from "@ant-design/icons";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import { Button, Layout, Menu, PageHeader } from "antd";
import "antd/dist/antd.css";
import React, { useRef, useEffect, useState } from "react";

import { BrowserRouter, Link } from "react-router-dom";
import "./App.css";
import { InnerContent, FooterContent } from "./components";

const { Header, Content } = Layout;

function getLibrary(provider) {
  return new Web3(provider);
}

function App() {
  const nodeMenu = useRef();
  const nodeMenuBtn = useRef();
  const nodeMenuLogo = useRef();

  const [route, setRoute] = useState();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [setRoute]);

  const responsiveWidth = 1080;

  // const siderWidth = 290;
  // const marginResponsive = Math.max(0, (window.innerWidth - responsiveWidth - siderWidth) / 2);

  const [isMobile, setIsMobile] = useState(
    // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    window.innerWidth < responsiveWidth,
  );

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  // const [w, setW] = useState(
  //   window.innerWidth < responsiveWidth ? window.innerWidth - 90 - 90 : window.innerWidth - 200 - 90 - 90,
  // );
  // const [h, setH] = useState((w * 9) / 16);
  // const w = 0;
  // const h = 0;
  window.addEventListener("mousedown", function (e) {
    if (isMobileMenuOpened) {
      if (
        nodeMenu &&
        nodeMenu.current &&
        nodeMenuBtn &&
        nodeMenuBtn.current &&
        nodeMenuLogo &&
        nodeMenuLogo.current &&
        !nodeMenu.current.contains(e.target) &&
        !nodeMenuBtn.current.contains(e.target) &&
        !nodeMenuLogo.current.contains(e.target)
      ) {
        setIsMobileMenuOpened(false);
      }
    }
  });

  window.addEventListener("resize", function () {
    // setW(
    //   isMobileMenuOpened || window.innerWidth >= responsiveWidth
    //     ? window.innerWidth - 200 - 90 - 90
    //     : window.innerWidth - 90 - 90,
    // );
    // setH((w * 9) / 16);

    setIsMobile(
      // /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
      window.innerWidth < responsiveWidth,
    );
  });

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
    <BrowserRouter>
      <div className="App">
        <Layout>
          <Header style={{ position: "fixed", zIndex: 1, width: "100%", height: 66 }}>
            {isMobile ? (
              <div ref={nodeMenuLogo} style={{ position: "fixed", top: -4, left: 40, zIndex: 1 }}>
                <PageHeader
                  subTitle={
                    <a href="/">
                      {" "}
                      <img
                        width="32"
                        heigth="32"
                        style={{ marginLeft: 10, "vertical-align": "top" }}
                        src="/pics/logo_coins_woodwater_large_square_64_reduced.png"
                      ></img>
                      <b style={{ fontSize: "20px", color: "black", padding: 12 }}>WATERWOOD</b>
                    </a>
                  }
                />
              </div>
            ) : (
     
              <Menu
              style={{ position: "fixed", top: 0, left: "50%", transform: "translate(-50%, 0%)", height: 66, width:638.25 }}
                selectedKeys={[route]}
                mode="horizontal"
              >
                <Menu.Item key="/" icon={<HomeFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/");
                    }}
                    to="/"
                  >
                    <b style={{ letterSpacing: 1 }}>Home</b>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/dashboard" icon={<ControlFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/dashboard");
                    }}
                    to="/dashboard"
                  >
                    <b style={{ letterSpacing: 1 }}>Dashboard</b>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/simulate" icon={<CalculatorFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/simulate");
                    }}
                    to="/simulate"
                  >
                    <b style={{ letterSpacing: 1 }}>Simulate</b>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/help" icon={<InfoCircleFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/help");
                    }}
                    to="/help"
                  >
                    <b style={{ letterSpacing: 1 }}>Help</b>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/invest" icon={<GoldFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/invest");
                    }}
                    to="/invest"
                  >
                    <b style={{ letterSpacing: 1 }}>Buy tokens</b>
                  </Link>
                </Menu.Item>
                
              </Menu>
            )}
            {route === "/dashboard" ? (
              ""
            ) : (
              <div style={{ zIndex: 1, position: "fixed", textAlign: "right", right: 0, top: 0, padding: 8 }}>
                <Button
                  type="primary"
                  id="laucnhAppMain"
                  style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
                  shape="round"
                  size="large"
                  onClick={() => {
                    window.location = "/dashboard";
                  }}
                >
                  <b style={{ letterSpacing: 1 }}>Launch App</b>
                </Button>
              </div>
            )}{" "}
          </Header>
        </Layout>

        <Layout>
          {isMobile ? (
            <div ref={nodeMenuBtn} style={{ zIndex: 1 }}>
              <PageHeader
                extra={[
                  <Button
                   id="openMobileMenu"
                    onClick={() => {
                      setIsMobileMenuOpened(!isMobileMenuOpened);
                    }}
                    icon={<MenuOutlined />}
                    type="primary"
                  ></Button>,
                ]}
                style={{ cursor: "pointer", position: "fixed", top: -4 }}
              />
            </div>
          ) : (
            // )
            <div style={{ zIndex: 1 }}>
              {" "}
              <a href="/">
                <PageHeader
                  avatar={{ src: "/pics/logo_coins_woodwater_large_square_64_reduced.png" }}
                  title="WATERWOOD"
                  style={{ cursor: "pointer", position: "fixed", top: 0, padding: 12 }}
                />
              </a>
            </div>
          )}

          {isMobileMenuOpened && isMobile ? (
            <div ref={nodeMenu}>
              <Menu
                style={{
                  boxShadow: "1px 1px 3px 1px rgba(0,0, 0, 0.2)",
                  borderRadius: "5px",
                  zIndex: 300,
                  position: "fixed",
                  width: 233,
                  fontWeight: 550,
                  fontSize: 18,
                  textAlign: "left",
                  marginLeft: 17,
                  marginTop: 65,
                  padding: 11,
                }}
                selectedKeys={[route]}
                mode="vertical"
              >
                <Menu.Item key="/" icon={<HomeFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/");
                    }}
                    to="/"
                  >
                    <b style={{ letterSpacing: 1 }}>Home</b>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/dashboard" icon={<ControlFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/dashboard");
                    }}
                    to="/dashboard"
                  >
                    <b style={{ letterSpacing: 1 }}>Dashboard</b>
                  </Link>
                </Menu.Item>

                <Menu.Item key="/simulate" icon={<CalculatorFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/simulate");
                    }}
                    to="/simulate"
                  >
                    <b style={{ letterSpacing: 1 }}>Simulate</b>
                  </Link>
                </Menu.Item>
                
                <Menu.Item key="/help" icon={<InfoCircleFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/help");
                    }}
                    to="/help"
                  >
                    <b style={{ letterSpacing: 1 }}>Help</b>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/invest" icon={<GoldFilled />}>
                  <Link
                    onClick={() => {
                      if (isMobile && isMobileMenuOpened) {
                        setIsMobileMenuOpened(false);
                      }
                      setRoute("/invest");
                    }}
                    to="/invest"
                  >
                    <b style={{ letterSpacing: 1 }}>Buy tokens</b>
                  </Link>
                </Menu.Item>
              </Menu>
            </div>
          ) : (
            ""
          )}

          <Layout className="site-layout" style={{ marginLeft: 0, marginTop: 65, paddingLeft: 45, paddingRight: 45 }}>
            <Content>
              <InnerContent />
            </Content>

            <FooterContent />
          </Layout>
        </Layout>
      </div>{" "}
    </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
