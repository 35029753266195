import React from "react";
import { Button, Card } from "antd";
import { DollarCircleFilled } from "@ant-design/icons";

export default function MintWOOD({ _ }) {
  const unMintedBlocks = _.lastMintBlockNumber ? _.lastBlockNumber - _.lastMintBlockNumber : 0;
  const unMintedDays = unMintedBlocks / 28800;
  const blockSinceFirstLockIn = _.firstLockInBlock ? _.lastBlockNumber - _.firstLockInBlock : 0;
  const  daysSinceFirstLockIn = blockSinceFirstLockIn / 28800;
  const yourMultiplierIn24h = Math.min(10, 1 + 9 * ((daysSinceFirstLockIn + 1) / 18)) * _.burnMultiplier;
  const dailyWOODgenerated =
    yourMultiplierIn24h && _.lockedInWATER ? yourMultiplierIn24h * 0.00000001 * 28800 * _.lockedInWATER : 0;
  // const unMintedWOOD = unMintedBlocks * _.timeMultiplier * _.burnMultiplier * _.lockedInWATER * 0.00000001;

  // const [visible, setVisible] = React.useState(false);
  // const [confirmLoading, setConfirmLoading] = React.useState(false);

  // const showPopconfirm = () => {
  // setVisible(true);
  // };

  const handleOk = () => {
    // setVisible(false);
    // setConfirmLoading(true);
    // setTimeout(() => {
    //   setVisible(false);
    //   setConfirmLoading(false);
    // }, 2000);

    const result = _.tx_without_notifications(
      _.customContractWOOD.connect(_.signer)["mintToAddress"](_.address, _.address, _.lastBlockNumber),
      update => {
        console.log("📡 Transaction Update:", update);
        if (update && (update.status === "confirmed" || update.status === 1)) {
          console.log(" 🍾 Transaction " + update.hash + " finished!");
          console.log(
            " ⛽️ " +
              update.gasUsed +
              "/" +
              (update.gasLimit || update.gas) +
              " @ " +
              parseFloat(update.gasPrice) / 1000000000 +
              " gwei",
          );
        }
      },
    );
    console.log("awaiting metamask/web3 confirm result...", result);
    // console.log(await result);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    // setVisible(false);
  };

  return (
    <div>
                    <Card style={{"background-color":_.STYLE.COLORCARD[0], marginTop:_.STYLE.MARGINCARD.top,marginBottom:_.STYLE.MARGINCARD.bottom}}>
      <div style={{ fontSize: 40 }} plain>
        <div style={{ display: "inline" }}>
          {" "}
          <img
            width="64"
            style={{ "vertical-align": "top" }}
            src="/pics/logo_coins_wood_large_square_108_reduced.png"
          ></img>
          <b> WOOD is Now Growing </b>
        </div>
      </div>
      <div>
        <h2 style={{ marginTop: 40 }}>
          Uncollected WOOD:{" "}
          {_.WOODmintAmount.toLocaleString(3, {
            maximumFractionDigits: 2,
          }) || 0}
        </h2>
        <p>
          ~{" "}
          {dailyWOODgenerated.toLocaleString(3, {
            maximumFractionDigits: 1,
          }) || 0}{" "}
          WOOD/day
        </p>
        {/* <h4>Total WOOD Supply: {_.totalSupplyWOOD || 0}</h4> */}
        {/* <div /> */}
        {/* <h4>Time Multiplier: {timeMultiplier}</h4>
          <h4>Burn Multiplier: {burnMultiplier}</h4> */}

        {/* <h4>
          <p>Your WOOD Balance: {_.WOODBalance || 0} </p>
        </h4> */}

        {/* <Popconfirm
          title="TIP: Before cash-in, burn some WOOD to increase Burn Multiplier and collect more WOOD"
          visible={visible}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
          okText="Cash-In"
        > */}
        <Button
          icon={<DollarCircleFilled />}
          onClick={handleOk}
          // onClick={showPopconfirm}
          type="primary"
          size="large"
          style={{ marginTop: 21, marginBottom: 26 }}
        >
          Cash-In
        </Button>
        {/* </Popconfirm> */}
        <p>
          Days since last cash-in:{" "}
          {unMintedDays.toLocaleString(3, {
            maximumFractionDigits: 1,
          }) || 0}
          {/* {unMintedBlocks || 0} blocks */}
        </p>
      </div>
      </Card>
    </div>
  );
}
