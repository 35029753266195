import React from "react";
import { Progress, Card } from "antd";

export default function Multipliers({ _ }) {
  const totalMultiplier = _.timeMultiplier * _.burnMultiplier;

  return (
    <div>
      {" "}
      <Card style={{"background-color":_.STYLE.COLORCARD[0], marginTop:_.STYLE.MARGINCARD.top,marginBottom:_.STYLE.MARGINCARD.bottom}}>
        <div>
          <div style={{ fontSize: 40 }} plain>
          {/* <img width="56" style={{"vertical-align": "center"}} src="/pics/logo_coins_woodwater_large_square_108_reduced.png"></img>
         <div style={{ display:"inline" , "white-space":"nowrap" }}>  */}
         <b>Total Bonuses - {!isNaN(totalMultiplier) ? totalMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
}) : 1}/500X</b>
         {/* </div>  */}
          </div>
        </div>
        <p>Lock-in WATER and burn WOOD to increase Multipliers</p>
        <div style={{ transform: "translate(50%, 0%)", width: "50%" }}>
          <h3 style={{ marginTop: 35 }}>
            <b>Burn Bonus</b>
          </h3>
          {/* <p>Burn more WOOD to increase your profit 50x</p> */}
          <Progress
            strokeColor={{
              "0%": "#108ee9",
              "100%": "#87d068",
            }}
            percent={(_.burnMultiplier / 50) * 100}
            status="active"
            showInfo={false}
          />
   
          <h1>
            <b>{!isNaN(_.burnMultiplier) && _.burnMultiplier !== undefined ? _.burnMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
}) : 1} / 50X</b>{" "}
          </h1>
        
          <h3 style={{ marginTop: 35 }}>
            <b>Time Bonus </b>
          </h3>
          <Progress
            strokeColor={{
              from: "#108ee9",
              to: "#87d068",
            }}
            percent={(_.timeMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
}) / 10) * 100}
            status="active"
            showInfo={false}
          />
          <h1>
            <b>
              {!isNaN(_.timeMultiplier) && _.timeMultiplier !== undefined ? _.timeMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
}) : 1} / 10X
            </b>{" "}
          </h1>
        </div>
      </Card>
    </div>
  );
}
