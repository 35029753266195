import { WATER_ABI, WOOD1_ABI, WOOD2_ABI, WATER_ADDRESS, WOOD_ADDRESS, WATER2_ADDRESS, WOOD2_ADDRESS, WATERTEST_ADDRESS, WOODTEST_ADDRESS } from "./waterwood";

export const STYLE = {
  COLORCARD: [
    "#ffffff",
    "#f9f9ff",
    "#eaf4fd",
    "#c0e8fa",
    "#f1f7fc",
    "#ecf5fc",

    ],

  MARGINCARD: { top: 0, bottom: 27 }
};

export const STYLEHOME = {
  COLORCARD: [
    "#ffffff",
    "#f9f9ff",
    "#eaf4fd",
    "#c0e8fa",
    "#f1f7fc",
    "#ecf5fc",

  ],

  MARGINCARD: { top: 0, bottom: 27 }
};

// ETH INFURA_ID
export const INFURA_ID = "0946db060fbe44c1aee67cedd11e5298";

// BSC MORALIS_ID
export const MORALIS_ID = "57eb4483ff6d41c47b82ecb7";
export const MORALIS_KEY = "1YmJYJiPVAumZIipSYqr9udmIMWuii1mdEW4UW5pVaiszCJdQek5e51mliasgA39";
// MY ETHERSCAN_ID, SWAP IN YOURS FROM https://etherscan.io/myapikey
export const ETHERSCAN_KEY = "PSW8C433Q667DVEX5BCRMGNAH9FSGFZ7Q8";

// BLOCKNATIVE ID FOR Notify.js:
export const BLOCKNATIVE_DAPPID = "0b58206a-f3c0-4701-a62f-73c7243e8c77";

export const NETWORK = chainId => {
  for (const n in NETWORKS) {
    if (NETWORKS[n].chainId === chainId) {
      return NETWORKS[n];
    }
  }
};

export const NETWORKS = {
  localhost: {
    name: "localhost",
    color: "#666666",
    chainId: 31337,
    blockExplorer: "",
    rpcUrl: "http://" + window.location.hostname + ":8545",//"https://rpc.scaffoldeth.io:48544",
  },
  mainnet: {
    name: "mainnet",
    color: "#ff8b9e",
    chainId: 1,
    rpcUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://etherscan.io/",
  },
  kovan: {
    name: "kovan",
    color: "#7003DD",
    chainId: 42,
    rpcUrl: `https://kovan.infura.io/v3/${INFURA_ID}`,
    blockExplorer: "https://kovan.etherscan.io/",
    faucet: "https://gitter.im/kovan-testnet/faucet", // https://faucet.kovan.network/
  },
  rinkeby: {
    name: "rinkeby",
    color: "#e0d068",
    chainId: 4,
    rpcUrl: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
    faucet: "https://faucet.rinkeby.io/",
    blockExplorer: "https://rinkeby.etherscan.io/",
  },
  ropsten: {
    name: "ropsten",
    color: "#F60D09",
    chainId: 3,
    faucet: "https://faucet.ropsten.be/",
    blockExplorer: "https://ropsten.etherscan.io/",
    rpcUrl: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  },
  goerli: {
    name: "goerli",
    color: "#0975F6",
    chainId: 5,
    faucet: "https://goerli-faucet.slock.it/",
    blockExplorer: "https://goerli.etherscan.io/",
    rpcUrl: `https://goerli.infura.io/v3/${INFURA_ID}`,
  },
  xdai: {
    name: "xdai",
    color: "#48a9a6",
    chainId: 100,
    price: 1,
    gasPrice: 1000000000,
    rpcUrl: "https://dai.poa.network",
    faucet: "https://xdai-faucet.top/",
    blockExplorer: "https://blockscout.com/poa/xdai/",
  },
  matic: {
    name: "matic",
    color: "#2bbdf7",
    chainId: 137,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mainnet.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://explorer-mainnet.maticvigil.com//",
  },
  bsc: {
    name: "binance smart chain",
    color: "#eee",
    chainId: 56,
    gasPrice: 1000000000,
    rpcUrl: "https://bsc-dataseed.binance.org",
    blockExplorer: "https://bscscan.com/",
    water_abi: WATER_ABI,
    water_address: WATER_ADDRESS,
    wood_abi: WOOD1_ABI,
    wood_address: WOOD_ADDRESS,
  },
  bsc2: {
    name: "binance smart chain",
    color: "#ccc",
    chainId: 56,
    gasPrice: 1000000000,
    rpcUrl: "https://speedy-nodes-nyc.moralis.io/57eb4483ff6d41c47b82ecb7/bsc/mainnet",
    blockExplorer: "https://bscscan.com/",
    water_abi: WATER_ABI,
    water_address: WATER2_ADDRESS,
    wood_abi: WOOD2_ABI,
    wood_address: WOOD2_ADDRESS,
  },
  bsctest: {
    name: "bsc testnet",
    color: "#ccc",
    chainId: 97,
    gasPrice: 1000000000,
    rpcUrl: "https://speedy-nodes-nyc.moralis.io/57eb4483ff6d41c47b82ecb7/bsc/testnet",
    faucet: "https://testnet.binance.org/faucet-smart",
    blockExplorer: "https://testnet.bscscan.com/",
    water_abi: WATER_ABI,
    water_address: WATERTEST_ADDRESS,
    wood_abi: WOOD1_ABI,
    wood_address: WOODTEST_ADDRESS,
  },
  bsc2test: {
    name: "bsc testnet",
    color: "#eee",
    chainId: 97,
    gasPrice: 1000000000,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    faucet: "https://testnet.binance.org/faucet-smart",
    blockExplorer: "https://testnet.bscscan.com/",
    water_abi: WATER_ABI,
    water_address: WATERTEST_ADDRESS,
    wood_abi: WOOD1_ABI,
    wood_address: WOODTEST_ADDRESS,
  },
  mumbai: {
    name: "mumbai",
    color: "#92D9FA",
    chainId: 80001,
    gasPrice: 1000000000,
    rpcUrl: "https://rpc-mumbai.maticvigil.com",
    faucet: "https://faucet.matic.network/",
    blockExplorer: "https://mumbai-explorer.matic.today/",
  },
};

export const PRESALE =  "/invest";
export const BUYWATERPS = PRESALE;
export const BUYWATERPSR = "https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x3c73799db546e98f23f738ea0871b6ced71d873a" ;

export const BUYWOODPS = "https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x5dfd107dab2a10b5034601fe26b71b3b4d20e64c";

export const BUYWATER = 
PRESALE;


export const BUYWOOD = BUYWOODPS;

