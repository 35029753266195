import React, { useState } from "react";
import { Card, Button, Slider, Popover } from "antd";
import { STYLEHOME, BUYWATERPS, BUYWOODPS ,BUYWATERPSR} from "../constants";
import CountDown from "ant-design-pro/lib/CountDown";
import { CaretRightFilled } from "@ant-design/icons";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";

var supportedChainIds = [];
for (var i = 0; i <= 100000; i++) {
  supportedChainIds.push(i);
}

const injected = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});


// generate a random list of numbers between 100,000 and 67,000,000 all different and sort them in ascending order
// const randomList2 = () => {
//   var list = [];
//   while (list.length < 100) {
//     var randomnumber = Math.floor(Math.random() * interval*3);
//     if (list.indexOf(randomnumber) > -1) continue;
//     list.push(randomnumber);
//   }
//   return list.sort((a, b) => a - b);
// };
// print the list of numbers to the console
// console.log(randomList2());
const randomTimes = [
  1641186849458, 1641203160726, 1641215825097, 1641240709496, 1641279090203, 1641381246009, 1641393644006,
  1641401058802, 1641423237892, 1641425059703, 1641471629298, 1641479241950, 1641534509045, 1641535228118,
  1641556840348, 1641585859269, 1641619441960, 1641623780710, 1641702541079, 1641706158535, 1641742492428,
  1641750982640, 1641774375589, 1641774747247, 1641776747474, 1641789946959, 1641804530486, 1641812400061,
  1641848002227, 1641879279232, 1641880906601, 1641881054468, 1641932118549, 1641983777701, 1641985110533,
  1641999159215, 1642098162895, 1642118239215, 1642135001728, 1642179533471, 1642213631397, 1642216985320,
  1642267340854, 1642295931781, 1642323000044, 1642357064896, 1642389162442, 1642404440139, 1642461517170,
  1642476176473, 1642488230858, 1642492123830, 1642504511694, 1642504996254, 1642552132315, 1642556716125,
  1642573095683, 1642625543923, 1642642213869, 1642718539135, 1642729715687, 1642753614554, 1642769552490,
  1642797291674, 1642823112424, 1642907213193, 1642933455989, 1643021740676, 1643087482651, 1643118502090,
  1643124550902, 1643146119231, 1643177971813, 1643234224325, 1643236862968, 1643324438969, 1643325515545,
  1643334525454, 1643375964542, 1643388059541, 1643398551916, 1643398563019, 1643427573206, 1643430810504,
  1643437896428, 1643438453731, 1643528088241, 1643529060326, 1643554322739, 1643562759731, 1643569006562,
  1643647894655, 1643689011821, 1643706080798, 1643718229234, 1643720279991, 1643739231874, 1643750615608,
  1643754807372, 1643772508023,
];
const randomIntervals = randomTimes.map(time => time + 300000000);
//  get the indice of the first number in the list that is greater than the current time
const getRandomIndex = () => {
  var index = 0;
  while (randomIntervals[index] < Date.now()) {
    index++;
  }
  return index;
};

const randomList = [
  58213, 2687398, 4441246, 4602297, 5202045, 5379869, 5414236, 6065028, 6478769, 6674326, 6770313, 6964661, 7467980,
  7640371, 7960348, 10165227, 10807690, 10871740, 10900335, 12065418, 12695662, 13838654, 14447485, 14635926, 15515513,
  16557906, 16943862, 17246501, 17435878, 18182234, 18673347, 18787254, 20279034, 21029079, 21327276, 22039791,
  22811671, 23226978, 23278368, 23330387, 24004049, 24796757, 24846303, 25055224, 25299321, 25993343, 26677322,
  27106642, 27817549, 28113559, 28209735, 30545772, 31213192, 32217027, 33300716, 34160063, 34582780, 34886711,
  35009344, 35257046, 35681020, 36340141, 36738507, 37747640, 38021984, 38130323, 38522330, 39832963, 40052396,
  40089139, 41058879, 42283193, 42541421, 43930183, 46424788, 46963326, 48561080, 49037824, 49417433, 49564738,
  50245041, 51053592, 51382905, 51551137, 51861421, 52695778, 54113678, 54178735, 57857577, 58646541, 58936674,
  59107681, 59537312, 60316140, 60713432, 61897347, 62172496, 63959710, 65856836, 66308173,
];

const boughtTokens = randomList[getRandomIndex()];
const startingDate = new Date(2022, 1, 2).getTime() - 2600000000;
const interval = Date.now() - startingDate;
const targetDate = (67000000 * interval) / boughtTokens + startingDate;
// const targetTime = targetDate;
const targetTime = new Date(2022, 4, 3).getTime() - 2600000000;

const marks = {
  100: {
    style: {
      color: "#1890ff",
    },
    label: <strong>100</strong>,
  },
  // 500: "500",

  1000: "1000",
  // 1500: "1500",
  // 2500: "2500",
  // 3500: "3500",
  // 4500: "4500",
  2000: "2000",
  3000: "3000",
  4000: "4000",
  5000: {
    style: {
      color: "#f50",
    },
    label: <strong>5000</strong>,
  },
};

export default function Presale({ priceBNB, priceWATER, priceWOOD }) {
  const { active, account, library, connector, activate, deactivate } = useWeb3React();

  async function connect() {
    try {
      await activate(injected);
    } catch (ex) {
      console.log(ex);
    }
  }

  function disconnect() {
    try {
      deactivate();
    } catch (ex) {
      console.log(ex);
    }
  }

  const [amount, setAmount] = useState(1000);
  const price = Math.floor(priceWATER * 0.95 * 10000) / 10000;
  const qtty = Math.floor(amount / price);

  const payurl = "invest.html?price=" + amount + "&qtty=" + qtty + "&address=" + account;

  const onChangeAmount = value => {
    setAmount(value);
  };

  const schedule = (
    <div align="left">
      <p style={{ fontSize: 13 }}>
        1st pre-sale: price:{" "}
        <b>
          $
          {price.toLocaleString(3, {
            maximumFractionDigits: 4,
          })}
        </b>{" "}
        - <b>{Math.round(((0.1373 - price) / 0.1373) * 100)}% discount</b> - <b>67,000,000</b> tokens allocated -{" "}
        <b>Started January 2, 2022</b>
        <br></br>
        2nd pre-sale: price:{" "}
        <b>
          $ 0.0055
          {/* {(priceWATER * 4).toLocaleString(3, {
  maximumFractionDigits: 4,
  })} */}
        </b>{" "}
        - <b>{Math.round(((0.1373 - 0.0055) / 0.1373) * 100)}% discount</b> - <b>68,000,000</b> tokens allocated -{" "}
        <b>April 3, 2022</b>
        <br></br> 3rd pre-sale: price:{" "}
        <b>
          $ 0.0275
          {/* {(priceWATER * 20).toLocaleString(3, {
  maximumFractionDigits: 4,
  })} */}
        </b>{" "}
        - <b>{Math.round(((0.1373 - 0.0275) / 0.1373) * 100)}% discount</b> - <b>68,000,000</b> tokens allocated -{" "}
        <b>July 3, 2022</b>
        <br></br> 4th pre-sale: price:{" "}
        <b>
          $ 0.0687
          {/* {(priceWATER * 50).toLocaleString(3, {
  maximumFractionDigits: 4,
  })} */}
        </b>{" "}
        - <b>{Math.round(((0.1373 - 0.0687) / 0.1373) * 100)}% discount</b> - <b>69,000,000</b> tokens allocated -{" "}
        <b>September 4, 2022</b>
        <br></br>{" "}
        <b style={{ width: 400 }} class="boxed">
          IEO price:{" "}
          <b>
            $ 0.1373
            {/* {(priceWATER * 100).toLocaleString(3, {
  maximumFractionDigits: 4,
  })} */}
          </b>{" "}
          {/* <b>273,000,000</b> tokens allocated - */}- <b>November 6, 2022</b>
        </b>
      </p>
    </div>
  );

  return (
    <div>
      <Card
        style={{
          "background-color": STYLEHOME.COLORCARD[0],
          marginTop: STYLEHOME.MARGINCARD.top,
          marginBottom: STYLEHOME.MARGINCARD.bottom,
        }}
      >
        <div align="center">
          <h1 style={{ fontSize: 50 }}>
            <b>Buy WATER at a discount</b>
          </h1>

          <p style={{ fontSize: 20 }}>
            <b>1st pre-sale</b> ends in{" "}
            <b>
              <CountDown
                class="boxed"
                style={{paddingLeft:14, fontSize: 16 }}
                target={targetTime}
                format={t => {
                  const D = Math.floor(t / 86400000);
                  const H = Math.floor((t - D * 86400000) / 3600000);
                  const M = Math.floor((t - D * 86400000 - H * 3600000) / 60000);
                  const S = Math.floor((t - D * 86400000 - H * 3600000 - M * 60000) / 1000);
                  return D + "d, " + H + "h, " + M + "m, " + S + "s";
                }}
              />
            </b>
          </p>
          {/* <br></br> */}
          <br></br>
          <br></br>
          <p>
            Price:{" "}
            <b>
              $
              {price.toLocaleString(3, {
                maximumFractionDigits: 5,
              })}
            </b>{" "}
            <br></br>
            <Popover
            trigger="click"
            placement="top"
            content={schedule}
            title={<u>Schedule:</u>}
          >        
          <a  style={{ color: "black" }}><u><b>{Math.round(((0.1373 - price) / 0.1373) * 100)}% discount</b></u> </a>
          </Popover>
            {/* <br></br><b>Minimum investment: $500</b> */}
            <br></br>
            Maximum investment: <b>$5,000</b>
            {/* <br></br>    Buy early, make <b>100x </b> at IEO  */}
            <br></br> Only <b>67,000,000</b> tokens allocated
            {/* <br></br>
            estimated time left{" "}
            <b>
              <CountDown
                class="boxed"
                style={{ paddingLeft: 14, fontSize: 20 }}
                target={targetTime}
                format={t => {
                  const D = Math.floor(t / 86400000);
                  const H = Math.floor((t - D * 86400000) / 3600000);
                  const M = Math.floor((t - D * 86400000 - H * 3600000) / 60000);
                  const S = Math.floor((t - D * 86400000 - H * 3600000 - M * 60000) / 1000);
                  return D + "d, " + H + "h, " + M + "m, " + S + "s";
                }}
              />
            </b> */}
            {/* <br></br> 1st pre-sale price:{" "}
            <b>
              $
              {priceWATER.toLocaleString(3, {
                maximumFractionDigits: 4,
              })}
            </b>{" "}
            - <b>67,000,000</b> tokens allocated - <b>Running</b> */}
          </p>
        </div>
        <br></br> <br></br>
        <br></br>
        <h2 style={{ fontSize: 29 }}>
          Invest $
          {amount.toLocaleString(3, {
            maximumFractionDigits: 0,
          })}
        </h2>
        {/* <p>How much WATER would you like:</p> */}
        <div align="center">
          <Slider
            marks={marks}
            min={100}
            max={5000}
            onChange={onChangeAmount}
            value={amount ? amount : 5000}
            step={50}
            style={{ width: "100%" }}
          />
        </div>
        <br></br>
        <h2 class="boxedd">
          Receive{" "}
          {qtty.toLocaleString(3, {
            maximumFractionDigits: 0,
          })}{" "}
          WATER tokens
        </h2>
        <br></br>
        <br></br>
        <div>
          {!active ? (
            <div>
              <br></br>
              <br></br>
              <Button shape="round" size="large" type="primary" onClick={connect}>
                <b style={{ letterSpacing: 1 }}>Connect wallet to continue</b>
              </Button>
            </div>
          ) : (
            <div>
              <p style={{ fontSize: 20 }}>
                You will receive{" "}
                {qtty.toLocaleString(3, {
                  maximumFractionDigits: 0,
                })}{" "}
                WATER tokens to <br></br>your address: <br></br>
                <b>
                  <u>{account}</u>
                </b>
              </p>
              {/* <Button onClick={disconnect}>Disconnect</Button> */}
              <br></br> <br></br>
              <p style={{ fontSize: 15 }}>
                Support: <b>invest@waterwood.finance</b>
              </p>
              {/* <br></br> */}
              <br></br>
              <br></br>
              <u style={{ fontSize: 20 }}>
                Pay $
                {amount.toLocaleString(3, {
                  maximumFractionDigits: 0,
                })}
                :
              </u>
              <br></br>
              {/* <br></br> */}
              <div align="center">
                {" "}
                <iframe
                  src={payurl}
                  scrolling="yes"
                  frameborder="no"
                  allowtransparency="true"
                  allowfullscreen="true"
                  style={{ width: "100%", height: "975px", border: "none", overflow: "hidden" }}
                ></iframe>
               
              </div>
            </div>
          )}
        </div>
        {/* <div align="center">
          <div style={{ margin: 15, maxWidth: 500 }}>
           
            Insert <b>valid email</b> address, you'll receive confirmation by email:
            <Input
              size={"large"}
  
              placeholder={"Your email address"}
   
              onChange={e => {
                if (e.target.value !== "") {
                  setReceivingAddress(e.target.value);
                }
              }}
            />
          </div>
          
        </div> */}
        {/* </Card> 
      <Card
        style={{
          "background-color": STYLEHOME.COLORCARD[0],
          marginTop: STYLEHOME.MARGINCARD.top,
          marginBottom: STYLEHOME.MARGINCARD.bottom,
        }}
      >
        <h1 style={{ fontSize: 50 }}>
            <b>Next pre-sales</b>
          </h1> */}
      </Card>

      <Card
        style={{
          "background-color": STYLEHOME.COLORCARD[0],
          marginTop: STYLEHOME.MARGINCARD.top,
          marginBottom: STYLEHOME.MARGINCARD.bottom,
        }}
      >
        <h1 style={{ fontSize: 55 }}>
          <b>Buy on Pancakeswap</b>
        </h1>
        <p>
          <br></br>
          <b> WATER</b> and <b>WOOD</b> trade <b> on Pancakeswap</b>
          <br></br>We made available a <b>small liquidity</b> as test fair launch
        </p>
        {/* <br></br>
        <br></br> */}
        <Button
          class="wateh"
          id="buyingWATERHomepage"
          size="large"
          rel="noopener noreferrer"
          onClick={() => {
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
              ? window.open(BUYWATERPSR, "_self")
              : window.open(BUYWATERPSR);
          }}
          style={{ margin: 26 }}
          icon={<CaretRightFilled />}
          type="primary"
          shape="round"
        >
          <b style={{ letterSpacing: 1 }}>Buy WATER</b>
        </Button>
        <Button
          class="wateh"
          id="buyingWOODHomepage"
          size="large"
          rel="noopener noreferrer"
          onClick={() => {
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
              ? window.open(BUYWOODPS, "_self")
              : window.open(BUYWOODPS);
          }}
          style={{ margin: 26 }}
          icon={<CaretRightFilled />}
          type="primary"
          shape="round"
        >
          <b style={{ letterSpacing: 1 }}>Buy WOOD</b>
        </Button>
      </Card>
    </div>
  );
}
