import React from "react";
import { Route, Switch } from "react-router-dom";
import {Simulation, Dashboard, Help, Home, Presale } from "../views";
import   {useExchangePrice} from "../hooks";
// displays a page header

export default function InnerContent({ w, h }) {
  
  // Prices as of 30/6/2021
  const priceBNB = useExchangePrice("BNB");
  // const priceWATER = 0.000180456;
  // const priceWOOD = 0.000488164;
  // const priceWOOD = 0.000232569;
  // const priceWATER = 0.000483695;
  // const priceWOOD = 0.0000176927;
  const priceWATER = useExchangePrice("WATER");
  const priceWOOD = useExchangePrice("WOOD");


  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Home priceBNB={priceBNB} priceWATER={priceWATER} priceWOOD={priceWOOD}/>
        </Route>
        <Route exact path="/dashboard">
          <Dashboard priceBNB={priceBNB} priceWATER={priceWATER} priceWOOD={priceWOOD} />
        </Route>
        <Route path="/simulate">
          <Simulation priceBNB={priceBNB} priceWATER={priceWATER} priceWOOD={priceWOOD}/>
        </Route>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/invest">
          <Presale priceBNB={priceBNB} priceWATER={priceWATER} priceWOOD={priceWOOD}/>
        </Route>
      </Switch>
    </div>
  );
}
