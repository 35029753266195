import { Button } from "antd";
import React from "react";
import { useLookupAddress } from "../hooks";

/*
  ~ What it does? ~

  Displays an Address, Balance, and Wallet as one Account component,
  also allows users to log in to existing accounts and log out

  ~ How can I use? ~

  <Account
    address={address}
    localProvider={localProvider}
    userProvider={userProvider}
    mainnetProvider={mainnetProvider}
    price={price}
    web3Modal={web3Modal}
    loadWeb3Modal={loadWeb3Modal}
    logoutOfWeb3Modal={logoutOfWeb3Modal}
    blockExplorer={blockExplorer}
  />

  ~ Features ~

  - Provide address={address} and get balance corresponding to the given address
  - Provide localProvider={localProvider} to access balance on local network
  - Provide userProvider={userProvider} to display a wallet
  - Provide mainnetProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. "0xa870" => "user.eth")
  - Provide price={price} of ether and get your balance converted to dollars
  - Provide web3Modal={web3Modal}, loadWeb3Modal={loadWeb3Modal}, logoutOfWeb3Modal={logoutOfWeb3Modal}
              to be able to log in/log out to/from existing accounts
  - Provide blockExplorer={blockExplorer}, click on address and get the link
              (ex. by default "https://etherscan.io/" or for xdai "https://blockscout.com/poa/xdai/")
*/

export default function Account({ address, ensProvider, web3Modal, loadWeb3Modal, logoutOfWeb3Modal }) {
  const modalButtons = [];
  const ens = useLookupAddress(ensProvider, address);
  let displayAddress = address.substr(0, 6);//+"..."+address.substr(-4, 6);
  const size = "short";

  if (ens && ens.indexOf("0x") < 0) {
    displayAddress = ens;
  } else if (size === "short") {
    displayAddress += "..." + address.substr(-4);
  } else if (size === "long") {
    displayAddress = address;
  }


  if (web3Modal) {
    if (web3Modal.cachedProvider) {
      modalButtons.push(
        <Button
          // type="primary"
          key="logoutbutton"
          id="Account_Logout"
          style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
          shape="round"
          size="large"
          onClick={logoutOfWeb3Modal}
        >
          <b style={{ letterSpacing:1}}>Logout from {displayAddress}</b>
        </Button>,
      );
    } else {
      modalButtons.push(
        <Button
         id="ConnectWallet"
          type="primary"
          key="loginbutton"
          style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
          shape="round"
          size="large"
          /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
          onClick={loadWeb3Modal}
        >
          <b style={{letterSpacing:1}}> connect wallet</b>
        </Button>,
      );
    }
  }

  // const { currentTheme } = useThemeSwitcher();

  // const display = minimized ? (
  //   ""
  // ) : (
  //   <span>
  //     {address ? (
  //       <Address address={address} ensProvider={ensProvider} blockExplorer={blockExplorer} />
  //     ) : (
  //       "Click CONNECT to connect to Waterwood"
  //     )}
  //     <Balance address={address} provider={localProvider} price={price} />
  //     <Wallet
  //       address={address}
  //       provider={userProvider}
  //       ensProvider={ensProvider}
  //       price={price}
  //       color={"#1890ff"}
  //     />
  //   </span>
  // );

  return (
    <div>
      {/* {display} */}
      {modalButtons}
    </div>
  );
}
