import React from "react";
import { Card, Row, Col, Divider, Button } from "antd";
import {
  CoreValues,
  TokenSpecs,
  DashboardOverview,
  MintingBonuses,
  LockinMore,
  EconomicModel,
  WhyWaterwood,
  SolveInflation,
} from "../docs";
import { STYLEHOME, STYLE, NETWORKS } from "../constants";
import { DisplayLottie, EcosystemInfo, SocialBar2, TokenInfo } from "../components";
import { receiveBalance } from "../helpers";
import { useContractReader, useExternalContractLoader } from "../hooks";
import { StaticJsonRpcProvider } from "@ethersproject/providers";

const targetNetwork = NETWORKS.bsc;
const localProvider = new StaticJsonRpcProvider(targetNetwork.rpcUrl);
const addressWOODContract = targetNetwork.wood_address;

export default function Help() {
  const customContractWOOD = useExternalContractLoader(localProvider, addressWOODContract, targetNetwork.wood_abi);

  const globalRatio = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "getGlobalRatio")) * 10 ** 8;

  const globalLockedamount = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "globalLockedamount"));

  const globalBurnedamount = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "globalBurnedamount"));

  const totalSupplyWOOD = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "totalSupply"));

  const totalSupplyWATER = 1386000000;

  const _ = {
    globalBurnedamount: globalBurnedamount,
    globalLockedamount: globalLockedamount,
    globalRatio: globalRatio,
    totalSupplyWATER: totalSupplyWATER,
    totalSupplyWOOD: totalSupplyWOOD,
    STYLE: STYLE,
  };

  return (
    // <div style={{ transform: "translate(12.5%, 0%)", width: "80%" }}>
    <div>
      <Card
        style={{
          "background-color": STYLEHOME.COLORCARD[0],
          marginTop: STYLEHOME.MARGINCARD.top,
          marginBottom: STYLEHOME.MARGINCARD.bottom,
        }}
      >
        <div align="center">
          <h1 style={{ fontSize: 40 }}>
            <b>WaterWood Mechanics explained</b>
          </h1>
          {/* <h2 style={{ fontSize: 25, paddingBottom: 35 }}>WaterWood Mechanics explained</h2> */}
          {/* <div style={{marginTop:45}}>
            <WhyWaterwood />
          </div>
          <div style={{marginTop:15, whiteSpace:"nowrap"}}>
            <CoreValues  />
          </div>
          <div style={{marginTop:15}}>
            <MintingBonuses />
          </div>
          <div style={{marginTop:15}} >
            <TokenSpecs />
          </div>
          <div style={{marginTop:15}}>
            <DashboardOverview />
          </div> */}
          <Row style={{ textAlign: "center", marginTop: 45 }}>
            <Col span={12}>
              <WhyWaterwood />
            </Col>
            <Col span={12}>
              <CoreValues />
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: 25 }}>
            <Col span={12}>
              <DashboardOverview />
            </Col>
            <Col span={12}>
              <MintingBonuses />
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: 25 }}>
            <Col span={12}>
              <TokenSpecs />
            </Col>
            <Col span={12}>
              <EconomicModel />
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: 25 }}>
            <Col span={12}>
              <LockinMore />
            </Col>
            <Col span={12}>
              <SolveInflation />
            </Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col span={12}></Col>
            <Col span={12}></Col>
          </Row>
        </div>
      </Card>
      <SocialBar2 />

      <EcosystemInfo _={_} />
    </div>
  );
}
