import { LoadingOutlined, DownOutlined, ClockCircleOutlined, PoweroffOutlined, DownloadOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { Timeline, Card, Divider, Steps } from "antd";
import React from "react";
import { BUYWOOD , BUYWATER } from "../constants";
// import Blockies from "react-blockies";

// const { Text } = Typography;



// displays a timeline for scaffold-eth usage

export default function TimelineDisplay(props) {
  const { Step } = Steps;
  return (
    <Card style={{ marginTop: 0, marginBottom: 27 }}>

      {/* <Divider style={{ marginTop: 0, marginBottom: 50 }} plain>
          Timeline
        </Divider>
        <Timeline mode="alternate">
          <Timeline.Item style={{ fontSize: 16 }}> Creation of WATER coin June 8th, 2021</Timeline.Item>
          <Timeline.Item style={{ fontSize: 16 }} color="green">
            WOOD Token created June 9th, 2021
          </Timeline.Item>
          <Timeline.Item style={{ fontSize: 16 }} dot={<PoweroffOutlined style={{ fontSize: "16px" }} />}>
            Website lauch 2021-06-20
          </Timeline.Item>

          <Timeline.Item style={{ fontSize: 16 }} color="red"> Launch on PancakeSwap: July 2021</Timeline.Item>

          <Timeline.Item style={{ fontSize: 16 }} dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}>
            Launch on main CEX including Binance: December 2021
          </Timeline.Item>
          <Timeline.Item style={{ fontSize: 16 }} dot={<DownOutlined style={{ fontSize: "16px" }} />}>
            Launch on other EVM compatible blockchains: Q1 2022
          </Timeline.Item>
          <Timeline.Item style={{ fontSize: 16 }} dot={<DownOutlined style={{ fontSize: "16px" }} />}>
            Governance and Tokenomics additions: Q2 2022
          </Timeline.Item>
          <Timeline.Item style={{ fontSize: 16 }} dot={<DownOutlined style={{ fontSize: "16px" }} />}>
            Stabilization of currency dynamics and growth of the economic value: Q3 2022
          </Timeline.Item>
        </Timeline> */}


      {/* <Divider style={{ marginTop: 0, marginBottom: 40 }} plain>
        Timeline
      </Divider> */}

<b>
        <b>
          <div>
            <font size="164">Timeline</font>
          </div>
        </b>
      </b>
      <br></br><br></br><br></br> <font size="6">2021</font><br></br><br></br>
      <Steps style={{ align: "center" }} type={"default"} size={"default"} responsive={true} current={3} direction="horizontal">
        <Step title="June 2021" description={<><a target="_blank" href="https://bscscan.com/address/0x3c73799db546e98f23f738ea0871b6ced71d873a#code">WATER</a>, <a target="_blank" href="https://bscscan.com/address/0x5dfd107dab2a10b5034601fe26b71b3b4d20e64c#code">WOOD</a> and, <a target="_blank" href="https://waterwood.finance">website</a> deployement</>} />

        <Step title="July 2021" description={<>Launch of <a target="_blank" href={BUYWATER}> WATER </a> and <a target="_blank" href={BUYWOOD}> WOOD </a> on PancakeSwap</>} />

        <Step title="December 2021" description={<>Launch of our <a target="_blank" href="https://gitcoin.co/grants/3415/waterwoodfinance-a-self-upgradable-defi-contract-"> Gitcoin Grant </a></>} />

      </Steps>
      <br></br><br></br><br></br><br></br><font size="6">2022</font><br></br><br></br>
      <Steps initial={3} style={{ align: "center" }} type={"default"} size={"default"} responsive={true} current={3} direction="horizontal">
      <Step  title="Q1 2022" description={<> <a target="_blank" href="https://gitcoin.co/issue/waterwood-network/smart-contracts/1/100026415"
        > Make WOOD contract updatable </a></>} />
        <Step title="Q2 2022" description={<> <a target="_blank" href="https://gitcoin.co/issue/waterwood-network/smart-contracts/2/100026416"
        > Add Liquidity Bonus to WOOD contract </a></>} />

        <Step title="Q3 2022" description={<> <a target="_blank" href="https://gitcoin.co/issue/waterwood-network/smart-contracts/4/100026750"
        > Creation of a Lottery System: WOOD Token </a></>} />
        <Step title="Q4 2022" description="Governance" />
        </Steps>
        <br></br><br></br><br></br><br></br><font size="6">2023</font><br></br><br></br>
      <Steps initial={7} style={{ align: "center" }} type={"default"} size={"default"} responsive={true} current={-1} direction="horizontal">
        <Step title="Q1 2023" description="Add WIND token to WATERWOOD ecosystem" />

      </Steps>
      <br></br>
    </Card>
  );
}
