import React, { useState } from 'react';
import { Modal, Button, Card } from 'antd';

export default function MintingBonuses({footer}) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Bonuses Explained"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
       {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}

        width={"75%"}
      ><Card>
          <div align="left"  style={{margin:-120, lineHeight:2}}>
          <br></br> <br></br>
              <h1 style={{lineHeight: 1.5}}>How do Bonuses work?</h1>
              <p>
                With every single block, you generate 0.00000001 WOOD per 1 WATER locked-in. This is your constant growing rate, and will show up as the real-time WOOD balance in your dashboard. You do not actually own the WOOD tokens until you press "Cash-in".
              </p>
              <p>
                In your dashboard, you will see the number of WOOD you can collect called "uncollected WOOD". In this example below, there are 150,000 uncollected blocks. This number, along with the bonuses, will be used to calculate the total amount of WOOD you can collect in the example at the bottom.
              </p>
              <p>
                Bonuses are applied to the total amount of uncollected blocks at the time of COLLECTING your WOOD tokens. Bonuses do not affect your constant rate of generating:

                0.00000001 WOOD / WATER. In other words, bonuses will affect your final WOOD token collectable at the time of pressing CASH-IN.
              </p>
              <p>
                Now let's learn about specific rate bonuses:
              </p>   <br></br>  
                <h1 style={{lineHeight: 1.5}}>
                <b>Time Bonus:</b></h1>
              <p>

                The maximum time bonus you can achieve is 10x. Once you have reached 10x Time Bonus, every time you Grow WOOD (which is every 3 seconds), you generate 10x the amount.
                </p>
              <p>
                10x Time Bonus: After you lock-in WATER, you will start receiving a small multiplier every block (~3 seconds). This lasts for about 3 weeks after which you gain a permanent 10x bonus to your WOOD growth rate (until you stop growing).
              </p> <br></br>
                <h1 style={{lineHeight: 1.5}}>  
                <b>Burn Bonus:</b></h1>
              <p>

                The value of WOOD originates from borrowing a portion of the worldwide WOOD growth rate when burning WOOD.
                </p>
              <p>
                50x Burn Bonus: If you burn 50 times the global average of burned WOOD, you will get 50x the mint rate. This is a global number that is based on how much WATER is currently powering WOOD growth and how much WOOD these addresses have burned. 
                </p>
              <p>
                In other words: (All the WOOD I've ever burned / my WATER locked-in size) must be 50 times greater than (global burned WOOD of active locked-in accounts / WATER powering active locked-in accounts).
              </p>
              <p>
                WOOD burned to an address is permanent for that address.
                </p>
              <p>
                As more people burn WOOD, the amount of WOOD tokens you need to burn increases, and as locked-in stop the amount of WOOD you need to burn decreases (keep in mind the formula is based on ratios, so by starting locking-in with 10 WATER you would need 10x the burn amount compared to someone doing the same with only 1 WATER).
                </p>
              <p>
                After you BURN some WOOD, you will see an updated BURN bonus in your dashboard.
                </p>
              <p>
                The two bonuses (TIME Bonus and BURN Bonus) are multiplicative. So you can get up to 500x WOOD collecting rate at the time of pressing CASH-IN. It is suggested to get max 500x Total Multiplier at time of collecting WOOD, so this means you will most likely buy some WOOD first and burn it to your address if you are not at max multiplier yet before collecting your WOOD.
              </p>
              <p>
                Remember, your dashboard bonuses are applied at the time of collecting WOOD. You will see an estimate of how much your WOOD (with applied bonuses) is worth.
              </p>   <br></br>
                <h1 style={{lineHeight: 1.5}}>
                An example calculation:
              </h1>
              <ol><li> Locking-In 5,000,000 WATER</li>
                <li>For 150,000 blocks</li>
                <li>7.5x time bonus multiplier</li>
                <li>26x burn multiplier</li></ol>

              <p><b>Total WOOD generated </b> = 0.00000001 * 5,000,000 * 150,000 * 7.5 * 26 = 1,462,500 WOOD</p>
          
          </div></Card>
      </Modal>
    </>
  );
};