import React, { useState } from "react";
import { Button, Card } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";

export default function ENABLE({ _ }) {
  const [newInputValue, setInputValue] = useState(
    "In order to interact with our smart contract, you need to Enable your address. This will only happen once.",
  );

  return (
    <div>
      <Card style={{"background-color":_.STYLE.COLORCARD[0], marginTop:_.STYLE.MARGINCARD.top,marginBottom:_.STYLE.MARGINCARD.bottom}}>
        <div style={{ marginTop: 18, marginBottom: 38 }}>
          <div style={{ fontSize: 40 }} plain>
            <b>Enable your Address</b>
          </div>
          <h2 style={{ marginTop: 40 }}>{newInputValue}</h2>
          <Button class="wateh" id="enable"
            size={"large"}
            type="primary"
            style={{ marginTop: 8 }}
            icon={<PoweroffOutlined />}
            onClick={async () => {
              const result = _.tx_without_notifications(
                _.customContractWATER.connect(_.signer)["authorizeOperator"](_.addressWOODContract),
                update => {
                  console.log("📡 Transaction Update:", update);
                  if (update && (update.status === "confirmed" || update.status === 1)) {
                    console.log(" 🍾 Transaction " + update.hash + " finished!");
                    console.log(
                      " ⛽️ " +
                        update.gasUsed +
                        "/" +
                        (update.gasLimit || update.gas) +
                        " @ " +
                        parseFloat(update.gasPrice) / 1000000000 +
                        " gwei",
                    );
                    setInputValue("You can Mint and Grow WOOD Now!");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3);
                  }
                },
              );
              console.log("awaiting metamask/web3 confirm result...", result);
              console.log(await result);
            }}
          >
            ENABLE
          </Button>
        </div>
      </Card>
    </div>
  );
}
