import { usePoller } from "eth-hooks";
import { useState } from "react";
import { MORALIS_KEY } from "../constants";


export default function useExchangePrice(token, pollTime) {

  const [price, setPrice] = useState(0);
  const pollPrice = () => {
    async function getPrice() {
      if (token === "WATER") {
        // make a request.get to the api with x-api-key header  and return the price  in the response body  as a string   
        const response = await fetch( "https://deep-index.moralis.io/api/v2/erc20/0x3c73799db546e98f23f738ea0871b6ced71d873a/price?chain=bsc&providerUrl=mainnet", {  
          headers: {    "x-api-key": MORALIS_KEY  }  });
        const json = await response.json(); 
        // console.log("WATER", json);
        const price = json.usdPrice;
        setPrice(price);
      }
      if (token === "WOOD") {
        // make a request.get to the api with x-api-key header  and return the price  in the response body  as a string   
        const response = await fetch( "https://deep-index.moralis.io/api/v2/erc20/0x5dfd107dab2a10b5034601fe26b71b3b4d20e64c/price?chain=bsc&providerUrl=mainnet", {  
          headers: {    "x-api-key": MORALIS_KEY  }  });
        const json = await response.json(); 
        // console.log("WATER", json);
        const price = json.usdPrice;
        setPrice(price);
      }
      if (token === "BNB") {
        // make a request.get to the api with x-api-key header  and return the price  in the response body  as a string   
        const response = await fetch( "https://deep-index.moralis.io/api/v2/erc20/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/price?chain=bsc&providerUrl=mainnet", {  
          headers: {    "x-api-key": MORALIS_KEY  }  });
        const json = await response.json(); 
        // console.log("WATER", json);
        const price = json.usdPrice;
        setPrice(price);
      }

    }
    getPrice();
  };
  usePoller(pollPrice, pollTime || 9777);

  return parseFloat(price);
}