import { Divider, Card } from "antd";
import React from "react";
import { Address } from "../components";
import { WATER_ADDRESS, WOOD_ADDRESS} from "../waterwood";
import { BUYWOOD, BUYWATER } from "../constants";

// displays a page header

export default function EcosystemInfo({ _ }) {
  return (
    <Card style={{"background-color":_.STYLE.COLORCARD[0], marginTop:_.STYLE.MARGINCARD.top,marginBottom:_.STYLE.MARGINCARD.bottom}} title={<b>ECOSYSTEM INFO</b>}><b><h3>    <b>  <div><b>
        <div>Global WOOD Burned: {_.globalBurnedamount.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
        <div>Global WATER Locked-In: {_.globalLockedamount.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
        <div>Global Burn Ratio: {(_.globalRatio * 100).toLocaleString(3, {
  
  maximumFractionDigits: 2
})}%</div>
        <Divider />
        <div>Total WOOD in Circulation: {_.totalSupplyWOOD.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
        <div>Total WATER Supply: {_.totalSupplyWATER.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
        <Divider />
        <div>
          WOOD Contract Address:
          <Address
            
            address={WOOD_ADDRESS}
            
            fontSize={16}
            size="long"
          />
        </div>
        <div>
          WATER Contract Address:
          <Address
  
            address={WATER_ADDRESS}
    
            fontSize={16}
            size="long"
          />
        </div>
        <Divider />

        <div>
          Buy WOOD
          <a class="wateh" id="buyingWOODEcosystem" target={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?"":"_blank"} href='https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x5dfd107dab2a10b5034601fe26b71b3b4d20e64c'>
            {" "}
            here
          </a>
        </div>
        <div>
          Buy WATER
          <a  class="wateh" id="buyingWATEREcosystem" target={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?"":"_blank"} href={BUYWATER}> here</a>
        </div>
        
        {/* <Divider /> */}
        </b>   </div></b></h3></b>
    </Card>
  );
}
