import React from "react";
import { Card } from "antd";
import {
  // Simulate,
  BurnWOOD,
  LockWATER,
  MintWOOD,
  UnlockWATER,
  APYInfo,
  Multipliers,
  Simulate,
  TokenInfo,
  EcosystemInfo,
  Loader,
} from "../components";

export default function GrowWOOD({ _ }) {
  return (
    <div>
      {!isNaN(_.lockedInWATER) && _.lockedInWATER !== undefined ? (
        _.lockedInWATER === 0 ? (
          <div >
            <div>
              <LockWATER _={_} />
            </div>{" "}
            {/* <div>
              <Card style={{ marginBottom: 0 }}>
                <BurnWOOD _={_} />
              </Card>
            </div> */}
          </div>
        ) : (
          <div>
            <div>
              <MintWOOD _={_} />{" "}
            </div>
            <div>
              <BurnWOOD _={_} />
            </div>
            <div>
              <UnlockWATER _={_} />{" "}
            </div>
            <APYInfo _={_} />
      <Multipliers _={_} />
     <Simulate _={_} /> 
      <TokenInfo _={_} />
      <EcosystemInfo _={_} />
          </div>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
}
