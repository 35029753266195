import { Card, Button} from "antd";
import React from "react";

// displays a page header

export default function DownloadMetamask({_}) {
  return (
    <Card style={{"background-color":_.STYLE.COLORCARD[0], marginTop:_.STYLE.MARGINCARD.top,marginBottom:_.STYLE.MARGINCARD.bottom}}><div>
    <div style={{ padding: 16, margin: "auto", margin: 64 }}>
      Connect wallet or{" "}
      {
        <Button class="wateh"
        id="DownloadingMetamaskDashboard" rel="noopener noreferrer" target="_blank" href="https://metamask.io/download">
          download Metamask
        </Button>
      }
    </div>
  </div></Card>
  
  );
}
