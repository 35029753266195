import React, { useState } from 'react';
import { Modal, Button, Card } from 'antd';

export default function EconomicModel({footer}) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Economic Model"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
       {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}

        width={"75%"}
      ><Card >
       <div align="left" style={{ margin: -120, lineHeight: 2 }}>
              <br></br>   <br></br>
              <h1 style={{ lineHeight: 2 }}> Introduction</h1>
              <p>In this section, we will dive in the mathematics of WaterWood. You will learn how the ecosystem variables are calculated </p><br></br>   <br></br>
              <h1 style={{ lineHeight: 3 }}> WOOD minting formula</h1>
            <p>
              <b>Uncollected WOOD Amount</b> = 0.00000001 * WATER tokens Locked-in * Uncollected Blocks * Burn Bonus Multiplier * Time bonus multiplier
            </p><br></br>   <br></br>
            <h1 style={{ lineHeight: 3 }}> Burn Bonus Multiplier</h1>
            <p>
              <b>My Ratio</b> = (My total burned WOOD for this address) / (My total locked-in WATER tokens)<br></br>
              <b>Global Ratio</b> = (Worldwide total burned WOOD for this address) / (Worldwide total locked-in WATER tokens)<br></br>
              <b>Bonus Multiplier</b> = min(50, MyRatio / Global Ratio)
            </p><br></br>   <br></br>
            <h1 style={{ lineHeight: 3 }}> Time Bonus Multiplier</h1>
            <p>
              <b>Time Multiplier</b> = min(10, Unminted Amount * 9 + 1)
            </p><br></br>   <br></br>
          </div></Card >
      </Modal>
    </>
  );
};