import React from "react";
import { Button, Card, Row, Col } from "antd";
import {
  CoreValues,
  TokenSpecs,
  DashboardOverview,
  MintingBonuses,
  LockinMore,
  EconomicModel,
  WhyWaterwood,
  SolveInflation,
} from "../docs";
import { BUYWOOD , BUYWATER } from "../constants";

export default function FooterContent({ _ }) {
  return (
    <Card style={{ 
      // boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
      backgroundColor: "#F8F8F8" , Marin: 15}} plain>
      <div>
        <Row style={{ textAlign: "center" }}>
          <Col style={{ color: "whblack", paddingTop: 0 }} span={8}>
            <b>Community</b>
          </Col>
          <Col style={{ color: "black", paddingTop: 0 }} span={8}>
          <b>Tokens</b>
          </Col>
          <Col style={{ color: "black", paddingTop: 0 }} span={8}>
           <b> Menu</b>
          </Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
          <Button rel="noopener noreferrer" target="_blank" href="https://discord.gg/Z2KESHVrt3" type="link">
              Discord
            </Button>
          </Col>
          <Col span={8}>
            <Button class="wateh" id="buyingWATERFooter"
              // rel="noopener noreferrer"
              target={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?"":"_blank"}
              href={BUYWATER}
              type="link"
            >
              Buy WATER
            </Button>
          </Col>
          <Col span={8}>
            <Button rel="noopener noreferrer" href="/" type="link">
              Home
            </Button>
          </Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <Button rel="noopener noreferrer" target="_blank" href="https://twitter.com/waterwoodmoney" type="link">
              Twitter
            </Button>
          </Col>
          <Col span={8}>
            <Button class="wateh" id="buyingWOODFooter"
              rel="noopener noreferrer"
              target={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?"":"_blank"}
              href={BUYWOOD}
              type="link"
            >
              Buy WOOD
            </Button>
          </Col>
          <Col span={8}>
            <Button rel="noopener noreferrer" href="/dashboard" type="link">
              Dashboard
            </Button>
          </Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <Button rel="noopener noreferrer" target="_blank" href="https://medium.com/@waterwood.finance" type="link">
              Medium
            </Button>
          </Col>
          <Col span={8}>
            <Button
              rel="noopener noreferrer"
              target="_blank"
              href="https://bscscan.com/token/0x3c73799db546e98f23f738ea0871b6ced71d873a"
              type="link"
            >
              WATER contract
            </Button>
          </Col>
          <Col span={8}>
          <Button rel="noopener noreferrer" href="/simulate" type="link">
              Simulate
            </Button>
           
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
          {/* <Button rel="noopener noreferrer" target="_blank" href="https://github.com/Waterwood-network" type="link">
              Github
            </Button> */}
            {/* <Button rel="noopener noreferrer" target="_blank" href="https://www.reddit.com/r/Waterwood/" type="link">
              Reddit
            </Button> */}
          </Col>
          <Col span={8}>
            <Button
              rel="noopener noreferrer"
              target="_blank"
              href="https://bscscan.com/token/0x5dfd107dab2a10b5034601fe26b71b3b4d20e64c"
              type="link"
            >
              WOOD contract
            </Button>
          </Col>
          <Col span={8}>
          <Button rel="noopener noreferrer" href="/help" type="link">
              Help
            </Button>
          </Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
          {/* <Button rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/WaterWood-Finance-100572899098211" type="link">
              Facebook
            </Button> */}
            </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            {/* <Button rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/waterwood-finance" type="link">
              Linkedin
            </Button> */}
            </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            {/* <Button rel="noopener noreferrer" target="_blank" href="https://t.me/waterwoodfinance" type="link">
              Telegram
            </Button> */}
            </Col>
          <Col span={8}></Col>
          <Col span={8}>    <Button rel="noopener noreferrer" href="/invest" type="link">
              Buy tokens
            </Button></Col>
        </Row>
      </div>
      <br></br>  <br></br>

{/* ----------------------------- */}

      <div>
        <Row style={{ textAlign: "black" }}>
          <Col style={{ color: "black", paddingTop: 0 }} span={8}>
          <b>Help</b>
          </Col>
          <Col style={{ color: "black", paddingTop: 0 }} span={8}>
            <b>Security</b>
          </Col>
          <Col style={{ color: "black", paddingTop: 0 }} span={8}>
            {/* Legal */}
          </Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <WhyWaterwood footer={true} />
          </Col>
          <Col span={8}>
            <Button
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/Waterwood-network/whitepaper/tree/main/audits"
              type="link"
            >
              Audit Report
            </Button>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            < CoreValues footer={true}/>
          </Col>
          <Col span={8}>
            <Button
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/Waterwood-network/whitepaper/blob/main/docs/waterwood-smart-contracts.md"
              type="link"
            >
              Whitepaper
            </Button>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <DashboardOverview footer={true} />
          </Col>
          <Col span={8}>
            <Button rel="noopener noreferrer" target="_blank" href="https://github.com/Waterwood-network" type="link">
              Github
            </Button>
            </Col>
          <Col span={8}></Col>
        </Row>

        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <MintingBonuses footer={true} />
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
             {/* <Button class="wateh" id="locking" style={{ marginTop: 8 }} size="large" type="primary" onclick={()=>{}}>
                  WATE?
                </Button> */}
                </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <TokenSpecs footer={true} />
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <EconomicModel footer={true} />
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <LockinMore footer={true} />
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col span={8}>
            <SolveInflation footer={true} />
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
      </div>




      <div style={{ color: "black", paddingTop: 25 }}><b>Copyright WaterWood 2021-2022</b></div>
    </Card>
  );
}
