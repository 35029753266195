import React, { useState } from 'react';
import { Modal, Button, Card } from 'antd';

export default function TokenSpecs({ footer }) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Tokens Explained"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
       {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}

        width={"75%"}
      ><Card >
          <div align="left" style={{ margin: -120, lineHeight: 2 }}>
            <br></br>   <br></br>
            <h1 style={{ lineHeight: 1.5 }}>Tokens properties</h1>


            <p>
              WATER is a BEP-20 compatible (ERC-777) implementation and was deployed on Binance mainnet with fixed supply of 1,386,000,000 WATER.
            </p>
            <p>
              WOOD is a NON-FIXED supply token in our ecosystem. WOOD has linear and predictable inflation & controlled burn.

              <ul><li><b>WATER lock/unlock</b>: To generate WOOD tokens you need to lock in some WATER. There is no minimum amount and the rewards scale linearly & predictably.</li>
                <li><b>Cash-in</b>: you can collect WOOD at any time, as long as your WATER tokens are still locked-in</li>
                <li><b>10x Time Bonus</b>: After you lock-in WATER for at least 1 hour, you will start receiving a small time multiplier every 3 seconds. This lasts for about 3 weeks after which you can gain a permanent 10x (until you unlock WATER)</li>
                <li><b>50x Burn Bonus</b>: If you burn 50 times the global burn average then you will get 50x the growth rate. This is a global number that is based on how much WATER is currently locked-in and how much WOOD these locked-in addresses burned. Keep in mind the formula is based on ratios, so by locking 5 WATER you would need 5x the burn amount compared to locking in 1 WATER. You can think of it this way: (All the WOOD I've <b>ever</b> burned / my WATER <b>locked-in</b>) must be 50 times greater than (<b>global</b> burned WOOD of <b>only</b> users that are <b>currently</b> locked-in / <b>global</b> WATER <b>locked-in</b>)</li>
                <li><b>The bonuses are multiplicative</b>: So you can get up to 500x WOOD growth rate. It is suggested to get max 500x multiplicative rate IF POSSIBLE at time of collecting WOOD.</li>
              </ul></p>
            <br></br><br></br>
            <h1 style={{ lineHeight: 1.5 }}>The most efficient way to Cash-in WOOD:</h1><p>
              <ol><li>Get 10x time bonus (3 weeks after locking).</li><li>Before cashing-in, make sure you burn as much WOOD as possible to get to max 50x burn bonus. Bonuses are calculated at the time of Cash-In.</li></ol></p>
            <br></br>   <br></br>



          </div></Card>
      </Modal>
    </>
  );
};