/* eslint-disable jsx-a11y/accessible-emoji */
import {
  CaretRightFilled,
  CaretRightOutlined,
  // ClockCircleOutlined,
  // DownOutlined,
  // FallOutlined,
  // PoweroffOutlined,
  // RiseOutlined,
} from "@ant-design/icons";
import { SolveInflation } from "../docs";
import { SocialBar2, Timeline, Team } from "../components";

// import { UrlJsonRpcProvider } from "@ethersproject/providers";
import { Button, Card, Divider, Table, Row, Col, Slider } from "antd";
import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { STYLEHOME, BUYWATER, NETWORKS } from "../constants";
// import { DisplayLottie } from "../components";
import { receiveBalance } from "../helpers";
import { useContractReader, useExternalContractLoader } from "../hooks";
import { StaticJsonRpcProvider } from "@ethersproject/providers";

const columns = [
  {
    title: "",
    dataIndex: "feature",
    key: "feature",
    // align:"center",
    render: text => <p style={{ color: "#000000" }}>{text}</p>,
  },
  {
    title: <img width="108" heigth="108" src="pics/logo_coins_woodwater_large_square_216_reduced.png"></img>,
    key: "WATER",
    dataIndex: "WATER",
    align: "center",
    render: text => (
      <p
        style={{
          // color: "#1890ff"
          // color: "#0071bd"
          // color:"#003660"
          color: "#2a8ae2",
          // color: "#2082dc"
        }}
      >
        {text}
      </p>
    ),
  },
  {
    title: <img width="108" heigth="108" src="pics/logo_coins_wood_large_square_216_reduced.png"></img>,
    key: "WOOD",
    dataIndex: "WOOD",
    align: "center",
    render: text => (
      <p
        style={{
          // color: "#fa8c16"
          // color:"#513418"
          // color:"#3d280a"
          color: "#a9794d",
        }}
      >
        {text}
      </p>
    ),
  },
];

// const columns = [
//   {
//     title: "",
//     dataIndex: "feature",
//     key: "feature",
//     // align:"center",
//     render: text => <p style={{ color: "#000000" }}>{text}</p>,
//   },
//   {
//     title: <img width="108" heigth="108" src="pics/logo_coins_woodwater_large_square_216_reduced.png"></img>,
//     key: "WATER",
//     dataIndex: "WATER",
//     align: "center",
//     render: text => <p
//     style={{
//       // color: "#1890ff"
//       // color:"#0071bd"
//       // color:"#063c61"
//       color:"#0086cc"
//      }}>{text}</p>,
//   },
//   {
//     title: <img width="108" heigth="108" src="pics/logo_coins_wood_large_square_216_reduced.png"></img>,
//     key: "WOOD",
//     dataIndex: "WOOD",
//     align: "center",
//     render: text => <p style={{ color: "#815b37" }}>{text}</p>,
//   },
// ];

const data = [
  {
    feature: "Official Name",

    WATER: <b>WATER</b>,
    WOOD: <b>WOOD</b>,
  },
  {
    feature: "Total Supply",

    WATER: "Fixed",
    WOOD: "Unlimited",
  },

  {
    feature: "Start Supply",

    WATER: "1,386,000,000 WATER",
    WOOD: "0 WOOD",
  },
  // {
  //   feature: "Token Source",

  //   WATER: "Minted at origin",
  //   WOOD: "Minted by locking WATER",
  // },
  // {
  //   feature: "Key Feature",

  //   WATER: "Lock-To-Mint Smart Contract",
  //   WOOD: "Global Burn Supply / Demand",
  // },

  // {
  //   feature: "Primary Utility",

  //   WATER: "Incentivized Cold Storage",
  //   WOOD: "On-Chain Mint & Burn Mechanism",
  // },
  {
    feature: "Technology",

    WATER: "Standard ERC-777 (ERC-20 and BEP-20 compatible)",
    WOOD: "Advanced ERC-777 (ERC-20 and BEP-20 compatible)",
  },
  // {
  //   feature: "Forked from",

  //   WATER: "Ethereum Datamine DAM Token (exact fork except for coin supply)",
  //   WOOD: "Ethereum Datamine Flux Token (exact fork with improved time and burn multipliers)",
  // },
  {
    feature: "Professional Audits",

    WATER: "SlowMist Security",
    WOOD: "SlowMist Security",
  },

  // {
  //   feature: "Smart Contract Address",

  //   WATER: "0x3c73799db546e98f23f738ea0871b6ced71d873a",
  //   WOOD: "0x5dfd107dab2a10b5034601fe26b71b3b4d20e64c",
  // },
];

// const vheigthParam = window.innerHeight - 65;

const targetNetwork = NETWORKS.bsc;
const localProvider = new StaticJsonRpcProvider(targetNetwork.rpcUrl);
const addressWOODContract = targetNetwork.wood_address;

export default function Home({ priceBNB, priceWATER, priceWOOD }) {
  const customContractWOOD = useExternalContractLoader(localProvider, addressWOODContract, targetNetwork.wood_abi);
  const globalLockedamount = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "globalLockedamount"));
  const globalBurnedamount = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "globalBurnedamount"));
  const totalSupplyWOOD = receiveBalance(useContractReader({ 1: customContractWOOD }, 1, "totalSupply"));

  const tvl = globalLockedamount * priceWATER;
  // const tvl = 821026198 * priceWATER;
  const dm = (globalBurnedamount + totalSupplyWOOD) * priceWOOD;
  // const dm = priceWOOD * 821026198 * 0.3324;
  const rateReducedInflation = (globalBurnedamount / (globalBurnedamount + totalSupplyWOOD)) * 100;

  const interactiveAPY = true;
  const onexAPY = ((1 * 10 * 0.00000001 * 28800 * 365 * priceWOOD) / priceWATER) * 100;
  // const fiftyxAPY = ((50 * 10 * 0.00000001 * 28800 * 365 * priceWOOD) / priceWATER) * 100;
  const [firstTime, setFirstTime] = useState(true);
  const [burnBonus, setburnBonus] = useState(25);
  const [APY, setAPY] = useState(burnBonus * onexAPY);
  const onChangeBurn = value => {
    setAPY(value * onexAPY);
    setburnBonus(value);
    setFirstTime(false);
  };

  return (
    // <div style={{ border: "1px solid #cccccc", padding: 16, marginTop: 64, width: "50%", transform: "translate(166px,0%)", marginLeft: "20%"}}>
    <div>
      <b>
        {/* <div style={{ transform: "translate(12.5%, 0%)", width: "80%" }}> */}
        <div>
          <Card
            style={{
              // "background-image": 'url("/pics/wave-haikei.svg")',
              // "background-repeat": "no-repeat",
              // "backgroundPositionX":"right",
              // "backgroundPositionY":"bottom",
              // "backgroundSize":"100%",
              // height: vheigthParam,
              // "border-radius": "50px",
              paddingTop: 40,
              paddingBottom: 65,
              marginTop: STYLEHOME.MARGINCARD.top,
              marginBottom: STYLEHOME.MARGINCARD.bottom,
            }}
          >
            {/* <font size="164">Unstoppable Demand Meets Immovable Supply</font> */}
            <img
              width="108"
              heigth="108"
              style={{ "vertical-align": "top" }}
              src="/pics/logo_coins_woodwater_large_square_216_reduced.png"
            ></img>
            <b>
              <div style={{ display: "inline", fontSize: 60, margin: 0 }}> WATERWOOD </div>
            </b>
            <div style={{ paddingTop: 20, fontSize: "1.6rem", fontWeight: 400 }}>
              <font style={{}}>
                {" "}
                An <b>AUDITED</b> dual coin ecosystem
                <br /> where{" "}
                <b>
                  WATER
                  {/* {" "}    
                  <img
                    width="25"
              style={{marginBottom:4, marginRight:3}}
                    src="/pics/logo_coins_woodwater_large_square_64.png"
                  ></img> */}
                </b>{" "}
                is locked to generate{" "}
                <b>
                  WOOD
                  {/* {" "}    
                  <img
                    width="25"
              style={{marginBottom:4, marginRight:3}}
                    src="/pics/logo_coins_wood_large_square_64.png"
                  ></img> */}
                </b>{" "}
                as dividends
              </font>
            </div>
            {/* <font size="32">Two coins called WATER and WOOD. Lock WATER to generate WOOD as dividends</font> */}
            {/* <h2 style={{ margin: 15 }}>Make money, eyes closed, by growing WOOD and recycling WATER </h2> */}
            <h3></h3>
            <Button
              class="wateh"
              id="buyingWATERHomepage"
              size="large"
              rel="noopener noreferrer"
              onClick={() => {
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                  ? window.open((window.location = BUYWATER), "_self")
                  : window.open((window.location = BUYWATER));
              }}
              style={{ margin: 26 }}
              icon={<CaretRightFilled />}
              type="primary"
              shape="round"
            >
              <b style={{ letterSpacing: 1 }}>Buy WATER Tokens</b>
            </Button>
            {/* <Button
              class="wateh"
              id="buyingWOODHomepage"
              size="large"
              rel="noopener noreferrer"
              onClick={() => {
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                  ? window.open(
                    BUYWOOD,
                    "_self",
                  )
                  : window.open(
                    BUYWOOD,
                  );
              }}
              style={{ margin: 26 }}
              icon={<CaretRightFilled />}
              type="primary"
              shape="round"
            >
              <b style={{ letterSpacing: 1 }}>Buy WOOD Tokens</b>
            </Button> */}
            <Button
              class="wateh"
              id="gotoDashboardHomepage"
              size="large"
              rel="noopener noreferrer"
              onClick={() => {
                window.location = "/dashboard";
              }}
              style={{ margin: 26 }}
              icon={<CaretRightOutlined />}
              type="primary"
              shape="round"
            >
              <b style={{ letterSpacing: 1 }}>Go to Dashboard</b>
            </Button>
            <div></div>
            {/* <Popover
              content={"WaterWood was inspired by Ethereum project Datamine , we show here their Whitepaper"}
              placement="bottomRight"
              trigger="hover"
            > */}
            <Button
              class="wateh"
              id="viewWhitepaperHomepage"
              type="link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/Waterwood-network/whitepaper/blob/main/docs/waterwood-smart-contracts.md"
            >
              View Whitepaper{" "}
            </Button>
            {/* </Popover> */}
            <Button class="wateh" id="visitHelpHomepage" type="link" rel="noopener noreferrer" href="/help">
              Help & knowledge base{" "}
            </Button>{" "}
            {/* <Popover
              content={"WaterWood smart contracts are forked from Ethereum Datamine's, we show here their audit"}
              placement="bottomRight"
              trigger="hover"
            > */}
            <Button
              class="wateh"
              id="viewAuditHomepage"
              type="link"
              rel="noopener noreferrer"
              target="_blank"
              href="https://github.com/Waterwood-network/whitepaper/tree/main/audits"
            >
              View security audit{" "}
            </Button>
            <div style={{ marginTop: 45, fontSize: 25 }}></div>
            {priceWATER * priceWOOD > 0 && interactiveAPY ? (
              <div>
                <div style={{ marginTop: 45 }}>
                  <font class="boxedd" size="5">
                    {" "}
                    APY:{" "}
                    {firstTime
                      ? (burnBonus * onexAPY).toLocaleString(3, {
                          maximumFractionDigits: 0,
                        })
                      : APY.toLocaleString(3, {
                          maximumFractionDigits: 0,
                        })}
                    %
                  </font>
                </div>

                <div style={{ margin: 15 }}>
                  <font>Burn Bonus: {burnBonus}x</font>
                  <div style={{ textAlign: "center" }}>
                    <div style={{ display: "inline-block" }}>
                      <Slider
                        style={{ width: 300 }}
                        min={1}
                        max={50}
                        onChange={onChangeBurn}
                        value={burnBonus}
                        step={0.1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: 45 }}>
                <font class="boxedd" size="5">
                  {" "}
                  APY:{" "}
                  {onexAPY.toLocaleString(3, {
                    maximumFractionDigits: 0,
                  })}
                  %
                </font>
              </div>
            )}
            <div style={{ marginTop: 45, fontSize: 18 }}>
              <div>
                <b class="boxeddd" style={{ fontSize: 20, margin: 25 }}>
                  Total Value Locked : $
                  {tvl.toLocaleString(3, {
                    maximumFractionDigits: 0,
                  })}{" "}
                </b>

                <b class="boxeddd" style={{ fontSize: 20, whiteSpace: "nowrap", margin: 25 }}>
                  Dividend Minted : $
                  {dm.toLocaleString(3, {
                    maximumFractionDigits: 0,
                  })}
                </b>
              </div>

              <div>
                <b class="water_box" style={{ margin: 25 }}>
                  WATER{" "}
                  <img
                    width="25"
                    style={{ marginBottom: 4, marginRight: 7 }}
                    src="/pics/logo_coins_woodwater_large_square_64.png"
                  ></img>
                  : $
                  {priceWATER.toLocaleString(3, {
                    maximumFractionDigits: 5,
                  })}{" "}
                </b>

                <b class="wood_box" style={{ whiteSpace: "nowrap", margin: 25 }}>
                  WOOD{" "}
                  <img
                    width="25"
                    style={{ marginBottom: 3, marginRight: 7 }}
                    src="/pics/logo_coins_wood_large_square_64.png"
                  ></img>
                  : $
                  {priceWOOD.toLocaleString(3, {
                    maximumFractionDigits: 5,
                  })}
                </b>
              </div>
            </div>
          </Card>

          <Card
            style={{
              "background-color": STYLEHOME.COLORCARD[0],
              marginTop: STYLEHOME.MARGINCARD.top,
              marginBottom: STYLEHOME.MARGINCARD.bottom,
            }}
          >
            <div align="center">
              {/* <Divider orientation="left" style={{  }}>How does it work?</Divider> */}
              {/* <br></br> */}

              <b>
                <b>
                  <div>
                    <font size="164"> Make Money every 3 seconds</font>
                  </div>
                </b>
              </b>

              {/* <p>When you lock-in WATER, you start receiving WOOD. </p> */}
              <h2>
                <p>
                  Every <b>3 seconds</b>, you generate <b>0.00000001 WOOD</b> for each <b>1 WATER</b> locked-in.
                  {/* Every 3 seconds, a small multiplier generates 0.00000001 WOOD for each WATER locked-in.  */}
                  {/* You can UNLOCK your WATER at any time. */}{" "}
                </p>
              </h2>

              <br></br>

              <Button
                class="wateh"
                id="LaunchAppHomepageMakeMoney3Sec"
                type="primary"
                size="large"
                style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
                onClick={() => {
                  window.location = "/dashboard";
                }}
                shape="round"
              >
                <b style={{ letterSpacing: 1 }}>Launch App</b>
              </Button>
            </div>
          </Card>

          <Card
            style={{
              "background-color": STYLEHOME.COLORCARD[0],
              marginTop: STYLEHOME.MARGINCARD.top,
              marginBottom: STYLEHOME.MARGINCARD.bottom,
            }}
          >
            <div align="center">
              {/* <Divider orientation="left" style={{  }}>How does it work?</Divider> */}
              {/* <br></br> */}

              <b>
                <b>
                  <div>
                    <font size="164"> What makes us Unique?</font>
                  </div>
                </b>
              </b>

              {/* <p>When you lock-in WATER, you start receiving WOOD. </p> */}
              <h2>
                <p>
                  We solve a <b>simple problem</b>, but a <b>very important one</b>:<br></br>
                  <br></br>
                  <p style={{ paddingBottom: 30, fontSize: 45 }}>
                    {/* <b class="boxedd">WE SOLVE INFLATION</b> */}
                    <SolveInflation home={true} />
                  </p>
                  {/* 
                  <br></br><br></br>Any other staking coin (Cardano, Polkadot, etc...) have an
                  APY just above their inflation rate. Few people are aware of that */}
                  {/* all their profit goes lost to inflation. */}
                  {/* <br></br><br></br> */}
                  {/* inflation rate just about 1% below their staking APY.  */}
                  {/* We have reduced inflation by 61.8%. More than any other staking coin in the Cryptoworld. */}
                </p>
              </h2>
              <br></br>
              {/* 
              <Button
                class="wateh"
                id="LaunchAppHomepageMakeMoney3Sec"
                type="primary"
                size="large"
                style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
                onClick={() => {
                  window.location = "/help";
                }}
                shape="round"
              >
                <b style={{ letterSpacing: 1 }}>SEE HOW</b>
              </Button> */}
            </div>
          </Card>

          <Card
            style={{
              "background-color": STYLEHOME.COLORCARD[0],
              marginTop: STYLEHOME.MARGINCARD.top,
              marginBottom: STYLEHOME.MARGINCARD.bottom,
            }}
          >
            <font size="164">How does it work?</font>
            <div className="site-card-wrapper" style={{ marginTop: 45 }}>
              <Row gutter={16} style={{ paddingBottom: 75, paddingTop: 45 }}>
                <Col span={8}>
                  {/* <Card title="Card title" bordered={false}> */}
                  <h2> 1. Lock WATER</h2>
                  <img
                    width="98"
                    // heigth="64"
                    style={{ marginTop: 57 }}
                    src="/pics/logo_coins_woodwater_large_square_108_reduced.png"
                  ></img>
                  <h3 style={{ marginTop: 50 }}>
                    <b>Lock-in WATER</b> to <b>generate WOOD</b>
                    {/* .<b> WATER</b> as <b>reserve currency, has no inflation</b> */}
                  </h3>
                  {/* </Card> */}
                </Col>
                <Col span={8}>
                  {/* <Card title="Card title" bordered={false}> */}
                  <h2> 2. Burn WOOD</h2>
                  <img
                    // width="64"
                    // heigth="64"
                    style={{ marginTop: 10 }}
                    src="/pics/logo_coins_woodburn_transparent_centered_108.png"
                  ></img>
                  <h3 style={{ marginTop: 10 }}>
                    Burn WOOD to <b>increase your Bonus</b>
                    {/* {" "} and <b> reduce hyper-inflation</b> */}
                    {/* <b>WOOD</b> is a <b>dividend-making currency</b> */}
                  </h3>
                  {/* </Card> */}
                </Col>
                <Col span={8}>
                  {/* <Card title="Card title" bordered={false}> */}
                  <h2>3. Cash WOOD</h2>
                  <img
                    width="98"
                    // heigth="64"
                    style={{ marginTop: 57 }}
                    src="/pics/logo_coins_wood_large_square_108_reduced.png"
                  ></img>
                  <h3 style={{ marginTop: 50 }}>
                    <b>Make passive income</b> with WOOD
                  </h3>
                  {/* </Card> */}
                </Col>
              </Row>
              {/* <br></br>
              <h3 style={{ paddingTop: 20, fontSize: "1.6rem", fontWeight: 400 }}><b>0%</b> WATER inflation, <br />WOOD inflation <b>Reduced by 61.8%</b></h3> */}
            </div>
            <br></br>
            {/* <br></br> */}
            <Button
              class="wateh"
              id="LearnMoreHomepageHowitWorks"
              type="primary"
              size="large"
              style={{ verticalAlign: "top", marginLeft: 8, marginTop: 4 }}
              onClick={() => {
                window.location = "/help";
              }}
              shape="round"
            >
              <b style={{ letterSpacing: 1 }}>Learn More</b>
            </Button>
          </Card>

          <Card
            style={{
              // "background-image": 'url("/pics/blurry-gradient-haikei.svg")',
              // "background-repeat": "no-repeat",
              // "backgroundPositionX":"right",
              // "backgroundPositionY":"bottom",
              // "backgroundSize":"100%",

              marginTop: STYLEHOME.MARGINCARD.top,
              marginBottom: STYLEHOME.MARGINCARD.bottom,
            }}
          >
            <div align="center">
              <b>
                <b>
                  <div>
                    <font size="164"> Bonuses up to 500x</font>
                  </div>
                </b>
              </b>
              <h2>
                <p>
                  A <b> 10x Time Bonus</b> and up to <b> 50x Burn Bonus</b> that cumulate to <b>500x</b>.
                </p>
              </h2>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <div align="center">
                <b>
                  <b>
                    <div>
                      <font size="164">Solving Inflation</font>
                    </div>
                  </b>
                </b>
                <h2>
                  <b>0%</b> WATER inflation, WOOD inflation{" "}
                  <b>
                    Reduced by{" "}
                    {rateReducedInflation.toLocaleString(3, {
                      maximumFractionDigits: 1,
                    })}
                    %
                  </b>
                  , <b>while keeping</b> <b>High APYs</b>. <br></br>
                  <br></br>
                </h2>
                <br></br>
                <Button
                  class="wateh"
                  id="LaunchAppHomepageNoInflation"
                  type="primary"
                  size="large"
                  style={{ verticalAlign: "top", margin: 15 }}
                  onClick={() => {
                    window.location = "/dashboard";
                  }}
                  shape="round"
                >
                  <b style={{ letterSpacing: 1 }}>Launch App</b>
                </Button>
                <Button
                  class="wateh"
                  id="LearnMoreHomepageNoInflation"
                  type="default"
                  size="large"
                  style={{ verticalAlign: "top", margin: 15 }}
                  onClick={() => {
                    window.location = "/help";
                  }}
                  shape="round"
                >
                  <b style={{ letterSpacing: 1 }}>Learn More</b>
                </Button>
              </div>
            </div>
            {/* removed lottie */}
          </Card>

          <Card
            style={{
              "background-color": STYLEHOME.COLORCARD[0],
              marginTop: STYLEHOME.MARGINCARD.top,
              marginBottom: STYLEHOME.MARGINCARD.bottom,
            }}
          >
            <Divider style={{ marginTop: 0, marginBottom: 40 }} plain>
              Tokenomics
            </Divider>
            <LazyLoad offset={200} once={true} resize={true}>
              <Table pagination={false} columns={columns} dataSource={data} />
            </LazyLoad>
          </Card>

          <SocialBar2 />
          {/* <Team /> */}
          <Timeline />
        </div>
      </b>
    </div>
  );
}
