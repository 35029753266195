import { Divider, Card } from "antd";
import React from "react";
// displays a page header

export default function TokenInfo({ _ }) {
  return (
    <Card style={{"background-color":_.STYLE.COLORCARD[0], marginTop:_.STYLE.MARGINCARD.top,marginBottom:_.STYLE.MARGINCARD.bottom}} title={<b>MY INFO</b>}>
      <b><h3>    <b>  <div><b>
        <div><b>WATER Balance: {_.WATERBalance.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</b></div>
        <div><b>WOOD Balance: {_.WOODBalance.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</b></div> 
 <Divider />
 <div>Burn Multiplier: {_.burnMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}x</div>
        <div>Time Multiplier: {_.timeMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}x</div>
        <Divider />
        <div>Locked-In WATER: {_.lockedInWATER.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
        <div>Grown WOOD: {_.WOODmintAmount.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
       
        <div>Burned WOOD: {_.burnedWOOD.toLocaleString(3, {
  
  maximumFractionDigits: 2
})}</div>
        <div>Burn Ratio: {(_.myRatio * 100).toLocaleString(3, {
  
  maximumFractionDigits: 2
})}%</div>
      
        </b>   </div></b></h3></b>
    </Card>
  );
}
