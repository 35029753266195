import { sendBalance } from "../helpers";
import React, { useState } from "react";
import { Card,Button, Input, Progress } from "antd";
import { CaretRightFilled, FireFilled } from "@ant-design/icons";
import {  BUYWOOD } from "../constants";

import { Loader } from "../components";

export default function BurnWOOD({ _ }) {
  const [newInputValue, setInputValue] = useState(_.WOODBalance);

  return (
    <div>
              <Card
                style={{
                  "background-color": _.STYLE.COLORCARD[0],
                  marginTop: _.STYLE.MARGINCARD.top,
                  marginBottom: _.STYLE.MARGINCARD.bottom,
                }}>
      <div style={{ fontSize: 40 }} plain>
        <div style={{ display: "inline" }}>
          <b> Burn WOOD </b>{" "}
          <img
            width="64"
            style={{ "vertical-align": "center" }}
            src="/pics/logo_coins_woodburn_transparent_centered_108_reduced.png"
          ></img>
        </div>
        {/* - to 50x multiplier */}
      </div>
      {!isNaN(_.WOODBalance) && _.WOODBalance !== "NaN" && _.WOODBalance !== undefined ? (
        <div>
          {/* <h4>Your WOOD Balance: {_.WOODBalance || 0}</h4> */}
          {/* <h2 style={{ marginTop: 40 }}>Total WOOD burned: {_.burnedWOOD.toLocaleString() || 0}</h2> */}
          <h2 style={{ marginTop: 40 }}>
            My WOOD Balance:{" "}
            {_.WOODBalance.toLocaleString(3, {
              maximumFractionDigits: 2,
            }) || 0}
          </h2>
          <div style={{ transform: "translate(50%, 0%)", width: "50%" }}>
            <Input
              disabled={_.WOODBalance < 10 ** -6}
              size={"large"}
              defaultValue={_.WOODBalance < 10 ** -6 ? "" : _.WOODBalance}
              placeholder={"No WOOD detected"}
              suffix={"WOOD"}
              onChange={e => {
                if (e.target.value !== "") {
                  setInputValue(e.target.value);
                } else {
                }
              }}
            />

            <div>
              {_.WOODBalance >= 10 ** -6 ? (
                <Button
                  class="wateh"
                  id="burningDashboard"
                  icon={<FireFilled />}
                  size="large"
                  type="primary"
                  style={{ marginTop: 8 }}
                  onClick={async () => {
                    const result = _.tx_without_notifications(
                      _.customContractWOOD
                        .connect(_.signer)
                        ["burnToAddress"](
                          _.address,
                          sendBalance(
                            !isNaN(newInputValue) && newInputValue !== "NaN" && newInputValue !== undefined
                              ? newInputValue.toString().includes(".")
                                ? (Math.floor(newInputValue * 10 ** 6) / 10 ** 6).toString()
                                : newInputValue.toString()
                              : _.WOODBalance.toString().includes(".")
                              ? (Math.floor(_.WOODBalance * 10 ** 6) / 10 ** 6).toString()
                              : _.WOODBalance.toString(),
                          ),
                        ),
                      update => {
                        console.log("📡 Transaction Update:", update);
                        if (update && (update.status === "confirmed" || update.status === 1)) {
                          console.log(" 🍾 Transaction " + update.hash + " finished!");
                          console.log(
                            " ⛽️ " +
                              update.gasUsed +
                              "/" +
                              (update.gasLimit || update.gas) +
                              " @ " +
                              parseFloat(update.gasPrice) / 1000000000 +
                              " gwei",
                          );
                        }
                      },
                    );
                    console.log("awaiting metamask/web3 confirm result...", result);
                    console.log(await result);
                  }}
                >
                  Burn WOOD
                </Button>
              ) : (
                <Button disabled icon={<FireFilled />} size="large" type="primary" style={{ marginTop: 8 }}>
                  Burn WOOD
                </Button>
              )}

              <Button class="wateh" id="buyingWOODdashboard"
                rel="noopener noreferrer"
                target={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
                    ? ""
                    : "_blank"
                }
                href={BUYWOOD}
                style={{ margin: 26 }}
                icon={<CaretRightFilled />}
                type="primary"
                size="large"
              >
                Buy WOOD
              </Button>

              <h3 style={{ marginTop: 24 }}>
                <b>Burn Multiplier</b>
              </h3>
              {/* <p>Burn more WOOD to increase your profit 50x</p> */}
              <div style={{ transform: "translate(50%, 0%)", width: "50%" }}>
                {" "}
                <Progress
                  strokeColor={{
                    "0%": "#108ee9",
                    "100%": "#87d068",
                  }}
                  percent={(_.burnMultiplier / 50) * 100}
                  status="active"
                  showInfo={false}
                />
                {/* <div /> */}
                <h1>
                  <b>
                    {_.burnMultiplier.toLocaleString(3, {
                      maximumFractionDigits: 2,
                    }) || 0}{" "}
                    X
                  </b>{" "}
                </h1>
              </div>
            </div>
          </div>
          
        </div>
      ) : (
        <Loader />
      )}
      </Card>
    </div>
  );
}
