import React, { useState } from 'react';
import { Card,Modal, Button } from 'antd';

export default function LockinMore({ footer }) {
   const [visible, setVisible] = useState(false);
  const titlePage = "Lock more WATER"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons
  const size = footer ? "" : "large";

  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
       {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}
        align={"center"}
        width={"75%"}
      ><Card >
          <div align="left" style={{margin:-120, lineHeight:2}} >        <br></br> <br></br>
          <h1 style={{ lineHeight: 2 }}>How to Lock more tokens?</h1>
          <p>
            Locking more WATER tokens is very easy. Simply unlock your existing tokens from the <a href="/dashboard">dasboard</a> and relock the desired amount immediately after.
          </p><p>You will lose your Time Bonus for about 3 weeks, but it will gradually come back to 10x within 3 weeks after relocking
          </p><br></br> <br></br>
        </div></Card>
      </Modal>
    </>
  );
};