// import { sendBalance } from "../helpers";
import React from "react";
// import { formatEther, parseEther } from "@ethersproject/units";
import { Card,Button,  Progress, Popconfirm } from "antd";
import { UnlockFilled } from "@ant-design/icons";

export default function UnlockWATER({ _ }) {
  const unLockedBlocks = _.lastMintBlockNumber ? _.lastBlockNumber - _.firstLockInBlock : 0;
  const unLockedDays = (unLockedBlocks / 28800);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const showPopconfirm = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
    // setConfirmLoading(true);
    // setTimeout(() => {
    //   setVisible(false);
    //   setConfirmLoading(false);
    // }, 2000);

    const result = _.tx_without_notifications(_.customContractWOOD.connect(_.signer)["unlock"](), update => {
      console.log("📡 Transaction Update:", update);
      if (update && (update.status === "confirmed" || update.status === 1)) {
        console.log(" 🍾 Transaction " + update.hash + " finished!");
        console.log(
          " ⛽️ " +
            update.gasUsed +
            "/" +
            (update.gasLimit || update.gas) +
            " @ " +
            parseFloat(update.gasPrice) / 1000000000 +
            " gwei",
        );

      }
    });
    console.log("awaiting metamask/web3 confirm result...", result);
    // console.log(await result);
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setVisible(false);
  };

  return (
    <div>
        <Card
                style={{
                  "background-color": _.STYLE.COLORCARD[0],
                  marginTop: _.STYLE.MARGINCARD.top,
                  marginBottom: _.STYLE.MARGINCARD.bottom,
                }}>
      <div style={{ fontSize: 40 }} plain>
     
         <div style={{ display:"inline" , "white-space":"nowrap" }}> 
       <b> Unlock WATER </b> <img width="64" heigth="64" style={{"vertical-align": "top"}} src="/pics/logo_coins_woodwater_large_square_108_reduced.png"></img>
       </div> 
      </div>
      <div style={{ margin: 8 }}>
        <h2 style={{ marginTop: 40 }}>
          Locked-in WATER: {_.lockedInWATER.toLocaleString(3, {
  maximumFractionDigits: 2
}) || 0}
          {/* ({unLockededBlocks.toLocaleString(3, {
  
  maximumFractionDigits: 2
})} Blocks) */}
        </h2><p>
          Last lock-in {unLockedDays.toLocaleString(3, {
  maximumFractionDigits: 1
}) || 0} days ago{/* {unLockedBlocks || 0} blocks */}
        </p>
        {/* <Popconfirm
          title="Before unlocking, make sure to cash-in on your WOOD. Otherwise they will be lost."
          visible={visible}
          onConfirm={handleOk}
          okButtonProps={{ loading: confirmLoading }}
          onCancel={handleCancel}
          okText="Unlock"
        > */}
          <Button icon={<UnlockFilled />} 
          // onClick={showPopconfirm}
          onClick={handleOk} 
          // onClick={handleOk} 
          size="large" id="Unlock_woodwater_Click" type="primary" style={{ marginTop: 8, marginBottom: 30 }}>
            UNLOCK
          </Button> 
        {/* </Popconfirm> */}

        <h3 style={{ marginTop: 24 }}>
          <b>Time Multiplier </b>
        </h3>
        <div style={{ transform: "translate(50%, 0%)", width: "50%" }}>
          {" "}
          <Progress
            strokeColor={{
              from: "#108ee9",
              to: "#87d068",
            }}
            percent={(_.timeMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
}) / 10) * 100}
            status="active"
            showInfo={false}
          />
          <h1>
            <b>{_.timeMultiplier.toLocaleString(3, {
  
  maximumFractionDigits: 2
}) || 0} X</b>{" "}
          </h1>
        </div>
      </div>
      </Card>
    </div>
  );
}
