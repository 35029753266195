import { Divider,Card } from "antd";
import React from "react";

// displays a page header

export default function APYInfo({ _ }) {
  // const unMintedBlocks = _.lastMintBlockNumber ? _.lastBlockNumber - _.lastMintBlockNumber : 0;
  // const unMintedDays = unMintedBlocks / 28800;

  const onexAPY = ((1 * 10 * 0.00000001 * 28800 * 365 * _.priceWOOD) / _.priceWATER) * 100;
  const fiftyxAPY = ((50 * 10 * 0.00000001 * 28800 * 365 * _.priceWOOD) / _.priceWATER) * 100;
  const yourAPY = _.burnMultiplier?
  ((_.burnMultiplier * 10 * 0.00000001 * 28800 * 365 * _.priceWOOD) / _.priceWATER) * 100:"∞";

  return (
    <div>
      <Card
        style={{
          "background-color": _.STYLE.COLORCARD[0],
          marginTop: _.STYLE.MARGINCARD.top,
          marginBottom: _.STYLE.MARGINCARD.bottom,
        }}
      >
        <div style={{ fontSize: 40 }} plain>
          {/* {_.lockedInWATER >0 ? ( */}
            <b>
              My APY:{" "}
              {yourAPY.toLocaleString(3, {
                maximumFractionDigits: 1,
              })}
              %
            </b>
          {/* ) : (

            <b>
              {" "}
              APY:{" "}
              {fiftyxAPY.toLocaleString(3, {
                maximumFractionDigits: 1,
              })}
              %{" "}
            </b>
          )} */}
        </div>
        <div>
          {/* <div>
            {_.lockedInWATER >0 ? (
              ""
            ) : (
      
              <b>Lock-In WATER to grow WOOD</b>
            )}
          </div> */}
          <div>
            APY (1x burn bonus):{" "}
            {onexAPY.toLocaleString(3, {
              maximumFractionDigits: 1,
            })}
            %
          </div>
          <div>
            APY (50x burn bonus):{" "}
            {fiftyxAPY.toLocaleString(3, {
              maximumFractionDigits: 1,
            })}
            %
          </div>
          <Divider/>
          <div>
            Price WATER: $
            {_.priceWATER.toLocaleString(3, {
              maximumFractionDigits: 8,
            })}
          </div>
          <div>
            Price WOOD: $
            {_.priceWOOD.toLocaleString(3, {
              maximumFractionDigits: 8,
            })}
          </div>
        </div>
      </Card>
      {/* {yourAPY ? (<Multipliers _={_}/>):("")} */}
    </div>
  );
}
