import LazyLoad from 'react-lazyload';
import React, { useState } from 'react';
import { Modal, Button, Card } from 'antd';

export default function DashboardOverview({ footer }) {
  const [visible, setVisible] = useState(false);
  const titlePage = "Use the Dashboard"
  const type_button = footer ? "link" : "default";
  const color = type_button === "link" ? "" : type_button === "primary" ? "white" : "black";
  const title = footer ? titlePage : <h2 style={{ margin: -2.5, color: color }}> {titlePage}</h2>;
  const minwidth = footer ? 0 : 200; // 250 for equal size for all buttons 
  const size = footer ? "" : "large";


  return (
    <>
      <Button size={size} type={type_button} style={{ minWidth: minwidth }} onClick={() => setVisible(true)}>
        {title}
      </Button>
      <Modal
        // title={titlePage}
        onOk={() => setVisible(false)}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={<Button onClick={() => setVisible(false)}>Close</Button>}

        width={"75%"}
      ><Card >
          <LazyLoad height={200} offset={100} once={true} resize={true}>
            <div align="left" style={{ margin: -120, lineHeight: 2 }}>
              <br></br>   <br></br>
              <h1 style={{ lineHeight: 1.5 }}>Introduction</h1>
              <p> The WaterWood dashboard is a feature-rich interface where you can lock and unlock your WATER tokens, burn and cash-in your WOOD tokens, and much more...</p>
              <br></br>
              <h1 style={{ lineHeight: 1.5 }}>Step-by-step Guide </h1>
              <br></br>
              <h2>Connect your wallet:</h2>
              <p>
                When you go to the <a href="/dashboard">dasboard</a> you'll be prompted to connect your wallet. If you have one, connect it and continue, otherwise click on <a href="https://metamask.io/download">download Metamask</a> and follow the procedure to set up an account
              </p>

              <img style={{ width: "60%" }} src="pics/docs/download_metamask.jpg" />

              <p>

              </p>

              <img style={{ width: "60%" }} src="pics/docs/metamask.jpg" />
              <br></br><br></br><br></br>
              <h2>Connect to BSC:</h2>
              <p>
            
             Once Metamask is setup, connect to Binance Smart Chain (BSC). Go to Metamask Settings > Networks > Add Network. Then input the following:
              </p>
              <ul>
                <li>Network Name : Binance Smart Chain</li>
                <li>New RPC URL: https://bsc-dataseed.binance.org</li>
                <li>Chain ID: 56</li>
                <li>Current Symbol: BNB</li>
                <li>Block Explorer: https://bscscan.com</li>
              </ul>
              <img style={{ width: "60%" }} src="pics/docs/bsc_rpc.jpg" />
              <br></br><br></br><br></br>
              <h2>Buy BNB:</h2>
              <p>
                Once this is done, you'll need to buy some BNB to interact with our smart contract, you can purchase BNB on <a target="_blank" href="https://binance.com">Binance Exchange</a> or on <a target="_blank" href="https://pancakeswap.finance"> Pancake Swap</a>. Then send them to your Metamask address.
              </p><br></br>
              <h2>Enable your wallet:</h2>
              <p>
                Once you have Metamask and are connected to Binance Smart Chain you will see a prompt to Enable your address. This is a one-time transaction and will allow you to start generating WOOD tokens. Click on ENABLE
              </p>
              <br></br>
              <img style={{ width: "100%" }} src="pics/docs/enable.jpg" /><br></br><br></br>
              <h2>Buy some WATER:</h2>
              <p>
                Then the dashboard will prompt you to buy some WATER tokens on Pancakeswap if you do not have some already. Go and click Buy WATER
              </p><br></br>
              <img style={{ width: "100%" }} src="pics/docs/buyWATER.jpg" /><br></br><br></br>
              <h2>Lock-in WATER:</h2>
              <p>
                At that point you are ready to lock-in your WATER and start generating WOOD. Click on Lock-In WATER
              </p>
              <br></br>
              <img style={{ width: "100%" }} src="pics/docs/lockin.jpg" /><br></br><br></br>
              <h2>Cash-in your WOOD:</h2>
              <p>
                Then the dashboard will show you that your WOOD are now growing. You can Cash-in at any time but it's advised to wait at least 3 weeks til you reach 10x time multiplier. You can also burn some WOOD to increase your burn multiplier to 50x and Cash-in even more
              </p><br></br>
              <img style={{ width: "100%" }} src="pics/docs/WOODgrowing.jpg" /><br></br><br></br>
              <h2>Burn WOOD:</h2>
              <p>
                Now you have the possibility to burn WOOD to increase your burn multiplier. Click on Buy WOOD if your WOOD balance is null
              </p>
              <br></br>
              <img style={{ width: "100%" }} src="pics/docs/buyWOOD.jpg" /><br></br>

              <p>
                If you already have some WOOD, simply click on Burn WOOD, you will see your burn multiplier increase in real time
              </p><br></br>
              <img style={{ width: "100%" }} src="pics/docs/burnWOOD.jpg" /><br></br><br></br>
              <h2>Unlock your WATER:</h2>
              <p>
                Finally, unlock your WATER to dispose of your coins, Make sure you cash-in on your WOOD first otherwise they will be lost forever. You are free to sell your WATER coins in the market or relock them in later
              </p><br></br>
              <img style={{ width: "100%" }} src="pics/docs/unlock.jpg" /><br></br><br></br>
              <h2>Stay informed:</h2>
              <p>
                At last you have a global overview of your data, with your Total Multiplier, your Tokens Info and the Ecosystem info
              </p>
              <br></br>
              <img style={{ width: "100%" }} src="pics/docs/info.jpg" />


              <br></br>   <br></br>

            </div>
          </LazyLoad>
        </Card>
      </Modal>
    </>
  );
};